import React, { useEffect, useState } from "react";

//Components
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import SuperAppButton from "../components/SuperAppButton";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Sidebar from "../components/Sidebar";
import FailedMessages from "../components/FailedMessages";
import AdminStatistics from "../components/AdminStatistics";
import Plans from "../components/Plans";
import Users from "../components/Users";
import AdminAgents from "../components/AdminAgents";
import AdminFeedback from "../components/AdminFeedback";
import AdminConfig from "../components/AdminConfig";
import { fetchSecure } from "../utils/utils";
import { Alert, Snackbar } from "@mui/material";

export default function Admin(props) {
  const [failedMessages, setFailedMessages] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");
  const [screen, setScreen] = useState("Config");
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  async function handleBackupAdminConfig() {
    const response = await fetchSecure(
      "/api/copyObject/adminConfig/adminConfigBackup/" + props.user
    );
    const data = await response.json();
    if (data?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    if (data.success) {
      alert("Admin Config has been backed up");
    } else {
      alert("Admin Config could NOT be backed up");
    }
  }

  async function handleRestoreAdminConfig() {
    const response = await fetchSecure(
      "/api/copyObject/adminConfigBackup/adminConfig/" + props.user
    );
    const data = await response.json();
    if (data?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    if (data.success) {
      alert("Admin Config has been restored");
    } else {
      alert("Admin Config could NOT be restored");
    }
  }

  const getAdminData = async (id) => {
    console.log("IN getAdminData - api/adminData");
    try {
      await fetchSecure("/api/getAdminData")
        .then((response) => response.json())
        .then((data) => {
          if (data?.code?.includes("auth")) {
            setOpenAlert(true);
          }
          setFailedMessages(data["failed_messages"]);
          setUsers(data["users"]);
          setFeedback(data["feedback"]);
          setId(data["id"]);
        });
    } catch (e) {
      console.log(`Failed to getAdminData`);
      console.log(e);
      console.log(e.stack);
    }
  };

  useEffect(() => {
    async function loadAdminData() {
      console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
      const data = await getAdminData();
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      <div className="admin-page-layout">
      <div className="custom-container">
          <Sidebar callback={setScreen} />
        {props.user && props.user.length > 0 && props.showLoginErrorMessage && (
          <div className="right-content">
            <>
            {screen === "Config" && (
                <>
                <h3>Admin Config</h3>
                  <SuperAppButton className="primary-gradient-btn" onClick={handleBackupAdminConfig}>
                    Backup Config
                  </SuperAppButton>
                  <RowMargin/>
                  <SuperAppButton className="primary-gradient-btn" onClick={handleRestoreAdminConfig}>
                    Restore Config
                  </SuperAppButton>
                  <AdminConfig
                    id={id}
                    user={props.user}
                    userDeep={props.userDeep}
                  />
                </>
              )}
              {screen === "Statistics" && (
                <AdminStatistics user={props.user} userDeep={props.userDeep} />
              )}
              
              {screen === "Plans" && (
                <Plans user={props.user} userDeep={props.userDeep} />
              )}
              {screen === "Failed Messages" && (
                <FailedMessages
                  failedMessages={failedMessages}
                  user={props.user}
                  userDeep={props.userDeep}
                />
              )}
              
              {screen === "Feedback" && (
                <AdminFeedback
                  feedback={feedback}
                  user={props.user}
                  userDeep={props.userDeep}
                />
              )}
              {screen === "Users" && (
                <Users user={props.user} userDeep={props.userDeep} />
              )}
              {screen === "AdminAgents" && (
                <AdminAgents user={props.user} userDeep={props.userDeep} />
              )}
              
            </>
          </div>
        )}
      </div>
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
