import React, { useContext } from 'react';

import { Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

const AdminFeedback = (props) => {
   const superAppStyle = useContext(GlobalContext)["superAppStyle"];
   const config = {};
   config["adminConfig"] = {
      "collection": "adminConfig",
      "name": "Admin Config",
      "showLikes": false,
      "buttonName": "Save",
      "security": {
         "create": "AllUsers",
         "edit": "AllUsers",
         "delete": "None",
         "view": "AllUsers",
      },
      flags: "",
      "fields": [
         {
            placeholder: "",
            display_name: "Config ID",
            name: "id",
            input_type: "display",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Agent Types",
            name: "agentTypes",
            input_type: "list",
            list_fields: ["agentType", "displayName", "URL", "rule"],
            display_list_field: "agentType",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Debug On",
            name: "debugOn",
            input_type: "checkbox",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Allow Any Website Scanning",
            name: "allowAnyScanning",
            input_type: "checkbox",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 1",
            name: "adminEmail1",
            input_type: "text",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Admin Email 2",
            name: "adminEmail2",
            input_type: "text",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Admin Email 3",
            name: "adminEmail3",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 4",
            name: "adminEmail4",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 5",
            name: "adminEmail5",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 6",
            name: "adminEmail6",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Tool ChatBot URL",
            name: "adminToolChatBot",
            input_type: "text",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Agent URL",
            name: "agentURL",
            input_type: "text",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Failed Responses",
            name: "failedResponses",
            input_type: "list",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Max Messages Per Month",
            name: "maxMessagesPerMonth",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Async File Size Threshold (MB)",
            name: "asyncFileSizeThreshold",
            help: "This is the maximum sized file we will try to run synchronously, before we give up and just tell the user to wait for an email when it's done",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Max FAQ Length (chars)",
            name: "maxFAQLength",
            help: "This is the system level maximum length one can enter into an FAQ input box.  Account/User level setting will override typically",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Days Till Purge",
            name: "daysTillPurge",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Alert Num Days Before Trial Ends",
            name: "alertNumDaysBeforeTrialEnds",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Environment Name",
            name: "environmentName",
            input_type: "text",
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Default FAQ Prompt",
            name: "defaultFAQPrompt",
            input_type: "textarea",
            colspan: 3,
            text_area_num_rows: 10,
            default: "",
            required: true
         },
         {
            placeholder: "",
            display_name: "Notification Webhook",
            name: "notificationWebhook",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Marketing Message",
            name: "marketingMessage",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Contact Us Request Types",
            name: "requestTypes",
            input_type: "list",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Privacy Policy",
            name: "privacyPolicy",
            input_type: "textarea",
            colspan: 3,
            text_area_num_rows: 10,
            default: "",
            required: true
         },
         {
            placeholder: "Creation Date",
            display_name: "Creation Date",
            name: "creationDate",
            input_type: "date",
            flags: "display",
            required: false
         },
         {
            placeholder: "Update Date",
            display_name: "Update Date",
            name: "updateDate",
            input_type: "date",
            flags: "display",
            required: false
         }
      ],
      "table": [
         { selector: (row) => row.id, name: "Admin ID", sortable: true, minWidth: "10%" }
      ],
      "filterField": "id",
      "exportFields": ["id"],
      "otherTableOptions": { "paginationPerPage": 20 },
   }
   return (
      <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
         <Grid item xs={12}>
            <Paper style={superAppStyle.cardStyle} elevation={8}>
               <SuperAppObject depth="1" style={superAppStyle} title="Admin Config" config={config["adminConfig"]} user={props.user} userDeep={props.userDeep} collection_name="adminConfig" id={props.id} display_state="DISPLAY" />
            </Paper>
         </Grid>
      </Grid>
   );
};

export default AdminFeedback;
