import React from "react";

/* THIS IS IN HERE BUT USED TO BE THE BORDER OF THE IFRAME */
const xxx = {
  width: "50%",
  margin: "10px",
  //border: '2px solid black',
  borderRadius: "5px !important",
  overflow: "hidden",
};

const backgroundColor = "var(--sec-bg)"; //"#dfeea3"; /* light Green */
const backgroundColorTableHeader = "var(--link-color)"; //"#32a111"; /* dark green */
const contextBackgroundColor = backgroundColor; //'#cb4b16';
const contextTextColor = "var(--light-color)";
const superAppStyle = {
  theme: {
    text: {},
    background: {
      /* default: "#e5f1ff" */
      default: backgroundColor /* light green */,
    },
    context: {
      background: backgroundColor, //'#cb4b16',
      text: "#FFFFFF",
      width: "40%",
    },
    divider: {},
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  customStyles: {
    header: {
      style: {
        minHeight: "56px",
        width: "100%",
      },
    },
    subHeader: {
      style: {
        /* backgroundColor: "#e5f1ff",*/
        backgroundColor: backgroundColor,
        minHeight: "52px",
      },
    },
    contextMenu: {
      style: {
        backgroundColor: contextBackgroundColor,
        fontSize: "18px",
        fontWeight: 400,
        color: contextTextColor,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 100%, 0)",
      },
    },
    rows: {
      style: {
        //minHeight: '72px', // override the row height
        fontSize: "1.2 rem",
        //backgroundColor: 'rgba(242, 38, 19, 0.9)',
        color: "black",
      },
    },
    headCells: {
      style: {
        fontSize: "1.2 rem",
        fontWeight: "700",
        backgroundColor: backgroundColorTableHeader,
        /* backgroundColor: '#007bff', OLD BLUE HEADER Aug 7, 2024 */
        color: "white",
        //paddingLeft: '8px', // override the cell padding for head cells
        //paddingRight: '8px',
      },
    },
    cells: {
      style: {
        //paddingLeft: '8px', // override the cell padding for data cells
        //paddingRight: '8px',
      },
    },
  },
  colStyleLeft: {
    /* used to used when we had a 2 column input format screen...leaving for possible return of that UI version */
    width: "50%",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth:
      "2px 0px 0px 2px" /* 25px top, 10px right, 4px bottom and 35px left */,
    fontWeight: "700",
    color: "#fff",
    backgroundColor: backgroundColorTableHeader,
    padding: "10px",
  },
  displayHeader: {
    /* used in DisplayDetails under SuperAppObject */ fontSize: "20px",
    backgroundColor: backgroundColorTableHeader,
    color: "white",
    marginRight: "30px",
    paddingLeft: "16px",
  },
  displayCreateEditHeader: {
    /* used in CreateEdit under SuperAppObject */ fontSize: "20px",
    backgroundColor: backgroundColorTableHeader,
    color: "white",
    marginRight: "25px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "16px",
  },
  btnPrimary: {
    /* used in SuperAppButton which is used all over the place */
    backgroundColor: backgroundColorTableHeader /* dark green */,
    color: "rgb(255, 255, 255)",
    borderColor: backgroundColorTableHeader /* dark green */,
  },
  sideBarInactive: {
    /* used in Admin and UserAdmin sidebar components */ border: "none",
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, .5)",
  },
  sideBarActive: {
    /* used in Admin and UserAdmin sidebar components */ border: "none",
    backgroundColor: "transparent",
    color: backgroundColorTableHeader,
  },
  homePageH1Style: {
    /* used on Home page */ paddingLeft: "100px",
    color: backgroundColorTableHeader,
  },
  homePageH1StyleInContainer: {
    /* used on Home page */ color: backgroundColorTableHeader,
  },
  checkBoxAccentColor: {
    /* used in CreateEdit under SuperAppObject for Checkbox */
    accentColor: backgroundColorTableHeader,
  },
  menuActive: {
    /* used in Header component */ color: backgroundColorTableHeader,
  },
  menuInactive: {
    /* used in Header component */ color: backgroundColor,
  },
  rowStyle: {
    width: "100%",
    fontSize: "24px",
  },
  fontStyle: {
    color: backgroundColorTableHeader,
  },
  gridStyle100: {
    /* used in 3 column DisplayDetails and CreateEdit under SuperAppObject */
    /* borderStyle: "solid",
      borderColor: "black", */
    fontSize: "16px",
    width: "100%",
  },
  gridStyle66: {
    /* used in 3 column DisplayDetails and CreateEdit under SuperAppObject */
    /* borderStyle: "solid",
      borderColor: "black", */
    fontSize: "16px",
    width: "66%",
  },
  gridStyle33: {
    /* used in 3 column DisplayDetails and CreateEdit under SuperAppObject */
    /* borderStyle: "solid", */
    /* borderColor: "black", */
    fontSize: "16px",
    width: "33%",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  displayName: {
    /* used in superapp object */ fontWeight: "700",
    paddingLeft: "3px",
  },
  displayValue: {
    /* used in superapp object */ paddingLeft: "10px",
    fontStyle: "italic",
  },
  colorContainer: {
    /* used in superapp object */ position: "relative",
    display: "flex",
    alignItems: "center",
  },
  flex100: {
    /* used in Grid */ flex: "100%",
    maxWidth: "100%" /* UNDO MAYBE */,
    marginBottom: "20px" /* UNDO MAYBE */,
    borderRadius: "20px" /* UNDO MAYBE */,
  },
  classes: {
    /* used in pages for Grid ..mostly the paper value, used to be more in here...TODO clean up to move up 1 level */
    paper: {
      width: "100%",
      padding: 10,
      margin: 10,
      textAlign: "center",
      fontSize: "24px",
    },
  },
  cardStyle: {
    /* used in Paper config */
    //"background-color": "#e5f1ff", //Light Blue from before Aug 7, 2024
    backgroundColor: backgroundColor /* light green */,
    borderRadius: "20px",
    padding: "10px",
    margin: "30px",
  },
  masterPageStyle: {
    /* used in some Cols */ width: "100%",
    padding: "25px 50px",
  },
  theme: {
    text: {},
    background: {
      /* default: "#e5f1ff" */
      default: backgroundColor /* light green */,
    },
    context: {
      background: backgroundColor, //'#cb4b16',
      text: "#FFFFFF",
      width: "40%",
    },
    divider: {},
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
};
const planConfig = {};
planConfig["plans"] = {
  collection: "plans",
  name: "Plans",
  showLikes: false,
  defaultSort: { selector: 1, asc: true },
  buttonName: "Save",
  security: {
    create: "AllUsers",
    edit: "AllUsers",
    delete: "AllUsers",
    view: "AllUsers",
  },
  flags: "",
  fields: [
    {
      placeholder: "",
      display_name: "Plan ID",
      name: "id",
      input_type: "display",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Not Active?",
      name: "notActive",
      input_type: "checkbox",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Recommended",
      name: "recommended",
      input_type: "checkbox",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Order",
      name: "order",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Plan Name",
      name: "planName",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Plan Display Name",
      name: "planDisplayName",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Max Agents",
      pt_display_name: "Agent(s)",
      name: "maxAgents",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Trial Length",
      name: "trialLength",
      input_type: "text",
      conditional: "planName == trial",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Max Message Count",
      pt_display_name: "Messages/mo",
      name: "maxMessageCount",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Max File Size (MB)",
      pt_display_name: "MB File Uploads",
      name: "maxFileSize",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Max FAQ Length",
      pt_display_name: "FAQ characters",
      name: "maxFAQLength",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Type of Bots",
      pt_display_name: "",
      name: "typeOfBots",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Production Use",
      pt_display_name: "Production Use",
      name: "productionUse",
      input_type: "checkbox",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Agent Chaining",
      pt_display_name: "Agent Chaining",
      name: "agentChaining",
      input_type: "checkbox",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Automated Agent Testing",
      pt_display_name: "Automated Testing",
      name: "automatedAgentTesting",
      input_type: "checkbox",
      default: "",
      required: false,
    },
    /*
                     {
                        placeholder: "",
                        display_name: "Time Bound (30 days)",
                        pt_display_name: "Time Bound (30 days)",
                        name: "timeBound",
                        input_type: "checkbox",
                        default: "",
                        required: false
                     },
      */
    {
      placeholder: "",
      display_name: "Cost",
      name: "cost",
      input_type: "text",
      default: "",
      required: false,
    },
    {
      placeholder: "",
      display_name: "Button Name",
      name: "buttonName",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "Creation Date",
      display_name: "Creation Date",
      name: "creationDate",
      input_type: "date",
      flags: "display",
      required: false,
    },
    {
      placeholder: "Update Date",
      display_name: "Update Date",
      name: "updateDate",
      input_type: "date",
      flags: "display",
      required: false,
    },
  ],
  table: [
    {
      selector: (row) => row.order,
      name: "Order",
      sortOrder: "asc",
      sortable: true,
      minWidth: "10%",
    },
    {
      selector: (row) => row.planName,
      name: "Plan Name",
      sortable: true,
      minWidth: "10%",
    },
    {
      selector: (row) => row.cost,
      name: "Cost",
      sortable: true,
      minWidth: "10%",
    },
    {
      selector: (row) => row.maxAgents,
      name: "Max Agents",
      sortable: true,
      minWidth: "10%",
    },
    {
      selector: (row) => row.maxMessageCount,
      name: "Max Msg Cnt",
      sortable: true,
      minWidth: "10%",
    },
    {
      selector: (row) => row.maxFileSize,
      name: "Max File Size (MB)",
      sortable: true,
      minWidth: "10%",
    },
  ],
  filterField: "planName",
  exportFields: ["planName"],
  otherTableOptions: { paginationPerPage: 20 },
};

const authConfig = {
  sessionId: sessionStorage.getItem("sessionId") || {},
};

const globalVariables = {
  debug: false,
  superAppStyle: superAppStyle,
  planConfig: planConfig,
  authConfig,
};
const GlobalContext = React.createContext(globalVariables);
export default GlobalContext;
