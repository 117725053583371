import React from 'react';

function formatTemplate(template, options) {
  return template.replace(/\[([^\]]+)\]/g, (match, key) => {
    return options[key] || match;
  });
}

// Example usage:
const displayTemplate = "[agentName] - [agentType]";
const options = {
  agentName: "John Doe",
  agentType: "Sales Agent"
};


// generate select dropdown option list dynamically
function Options({ options, config, def }) {
  console.log(JSON.stringify(config));   
  console.log(JSON.stringify(options));   
  if (options && options.length > 0 && typeof options[0] === 'object') {
     return (
       options.map(option => { 
         var formattedString = formatTemplate(config.dropdown_display_template, option);
         if (formattedString === config.dropdown_display_template) {
            formattedString = option[config.dropdown_value_key];
         }
         return (<option key={option[config.dropdown_value_key]} value={option[config.dropdown_value_key]}>
                    {formattedString}
                </option>)
       })
     );
  } else { /* must be a string ....might want to be explicit here */
     
     return (
       options.map(option => {
         //const attributes = {}
         //console.log(option + "," + def);
         //if (option === def) attributes["selected"] = "true";
         return (<option key={option} >
           {option}
         </option>)
         })
     );
  }
}

export default Options;

