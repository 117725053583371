import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { Alert, Box, Snackbar } from "@mui/material";

export default function AdminStatistics(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [countOfAgentTypes, setCountOfAgentTypes] = useState({});
  const [countOfPlans, setCountOfPlans] = useState({});
  const [openAlert, setOpenAlert] = useState(false);

  const loadStats = async () => {
    try {
      await fetchSecure("api/getAdminStats")
        .then((response) => response.json())
        .then((data) => {
          if (data?.code?.includes("auth")) {
            setOpenAlert(true);
          }
          setCountOfAgentTypes(data["countOfAgentTypes"]);
          setCountOfPlans(data["countOfPlans"]);
        });
    } catch (e) {
      console.log(`Failed to loadStats`);
      console.log(e);
      console.log(e.stack);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    //console.log("IN HERE AdminStatistics UseEffect");
    async function loadComponent() {
      await loadStats();
    }
    loadComponent();
  }, []);

  return (
    <React.StrictMode>
      <Box ref={containerRef} fluid>
        <Snackbar
          open={openAlert}
          autoHideDuration={1000}
          onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
            Session expired! Please login again
          </Alert>
        </Snackbar>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            <h3>Statistics</h3>
            <h4>Count of Plans: {JSON.stringify(countOfPlans)}</h4>
            <h4>Count of Agent Types: {JSON.stringify(countOfAgentTypes)}</h4>
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}

      </Box>
    </React.StrictMode>
  );
}
