import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useMyContext } from "../MyContext";

import { Navbar, Nav } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { FaCircleUser } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

//Components

import WixLoginBar from "./WixLoginBar";
import SuperAppButton from "./SuperAppButton";
import { Alert, Snackbar } from "@mui/material";

function Header(props) {
  const [user, loading, error] = useAuthState(auth);
  const { data } = useMyContext();
  const [searchVal, setSearchVal] = useState();
  //const [activeMenu, setActiveMenu] = useState(data["environmentName"] === 'Development'?"home":"agents"); used to show only some stuff in dev
  const [activeMenu, setActiveMenu] = useState("home");
  const [member, setMember] = useState("");
  const [showLoginButton, setShowLoginButton] = useState(true);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [expanded, setExpanded] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const location = useLocation();
  var pathname = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const globalData = useContext(GlobalContext);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setActiveMenu("home");

      if (pathname.length > 0) setActiveMenu(pathname);
      //setMember(Cookies && Cookies.get("XRTSG")?JSON.parse(Cookies.get("XRTSG"))["loggedInUser"]:"");
      const sessionId = globalData.authConfig.sessionId;
      // Cookies && Cookies.get("XRTSG")
      //   ? JSON.parse(Cookies.get("XRTSG"))["sessionId"]
      //   : "";
      console.log({ sessionId });

      if (sessionId && sessionId.length > 0) {
        console.log(encodeURIComponent(sessionId));
        const response = await fetchSecure(
          "/api/getSession/" + encodeURIComponent(sessionId)
        );
        const data = await response.json();
        if (data?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        setMember(data["userId"]);
      }
    }
    loadComponent();
  }, [data]);

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setMember(user && user.email);
    }
    loadComponent();
  }, [user]);

  /* NOT USED CURRENTLY...used if we have a search field in the header 
    const searchChange = val => {
      if (val && val.length) {
        setSearchVal(val[0]);
      } else {
        setSearchVal("");
      }
    };
    const submitSearch = () => {
      if (searchVal) {
        if (typeof searchVal === 'object' && searchVal !== null) {
          console.log(JSON.stringify(searchVal));
          typeahead.getInstance().clear();
          props.history.push(`/search/` + searchVal.name);
          console.log("IN HERE OBJECT");
        }
        else {
          typeahead.getInstance().clear();
          props.history.push(`/search/${searchVal}`);
          console.log("IN HERE");
        }
      }
      else {
        console.log("IN HERE 2");
        // GET VALUE FROM DOM
      }
    };
    let typeahead; * just leaving in for future use *
  */

  const editProfile = () => {
    props.history.push(`/user`);
  };

  function isAdmin(props) {
    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("admin") >= 0
    )
      return true;

    return false;
  }
  function loggedIn() {
    if ((user && user.email && user.email.length > 0) || member) return true;

    return false;
  }

  /* Need space between when 2 bars */
  const style = {
    display: "flex",
    justifyContent: props.user !== "" ? "space-between" : "end",
  };
  const capitalize = (str) => {
    if (str === null) return "";
    if (typeof str === "undefined") return "";
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const [afterLoginUserInfo, setAfterLoginUserInfo] = useState(false);
  const [advertisement,setAdvertisement] = useState(false);

  //className="mr-2"
  //allowNew
  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      {location.pathname !== "/signin" && (
        <Navbar
          variant='light'
          expand='lg'
          expanded={expanded}
          className='main-header'>
          <div className='custom-container'>
            <IndexLinkContainer to='/' activeClassName=''>
              <Navbar.Brand href='#'>
                <img src='/images/genAIAgentBuilder-logo.png' alt='' />
              </Navbar.Brand>
            </IndexLinkContainer>
            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              onClick={() => {
                setExpanded(expanded ? false : true);
              }}
            />
            <Navbar.Collapse
              id='basic-navbar-nav'
              className={expanded ? "show" : ""}
              style={style}>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setExpanded(expanded ? false : true);
                }}>
                <AiOutlineClose />
              </button>
              <Nav>
                {props.user !== "" && (
                  <>
                    {!props.iframeLogin && (
                      <Navbar.Text>
                        <Link
                          to={"home"}
                          className={
                            activeMenu === "home"
                              ? "active nav-link"
                              : "nav-link"
                          }
                          style={
                            activeMenu === "home"
                              ? superAppStyle.menuActive
                              : superAppStyle.xxinactive
                          }
                          onClick={() => {
                            setActiveMenu("home");
                          }}>
                          Home
                        </Link>
                      </Navbar.Text>
                    )}
                    {props.userDeep &&
                      props.userDeep.planName !== "unregistered" && (
                        <>
                          <Navbar.Text>
                            <Link
                              to={"agents"}
                              className={
                                activeMenu === "agents"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "agents"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("agents");
                              }}>
                              Agents
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"help"}
                              className={
                                activeMenu === "help"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "help"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("help");
                              }}>
                              Help
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"contact"}
                              className={
                                activeMenu === "contact"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "contact"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("contact");
                              }}>
                              Contact
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"user_admin"}
                              className={
                                activeMenu === "user_admin"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "user_admin"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("user_admin");
                              }}>
                              Admin
                            </Link>
                          </Navbar.Text>
                        </>
                      )}
                    {isAdmin(props) && (
                      <Navbar.Text>
                        <Link
                          to={"admin"}
                          className={
                            activeMenu === "admin"
                              ? "active nav-link"
                              : "nav-link"
                          }
                          style={
                            activeMenu === "admin"
                              ? superAppStyle.menuActive
                              : superAppStyle.xxinactive
                          }
                          onClick={() => {
                            setActiveMenu("admin");
                          }}>
                          System Admin
                        </Link>
                      </Navbar.Text>
                    )}
                  </>
                )}
                <Navbar.Text>
                  <div className='nav-link info-text' style={superAppStyle.headerText}>
                    {" "}
                    {Object.keys(props.userDeep).length > 0 && (
                      <>Plan: {capitalize(props.userDeep["planName"])}</>
                    )}
                    {"  "}
                  </div>
                </Navbar.Text>
                <Navbar.Text>
                  <div
                    className='nav-link info-text'
                    style={superAppStyle.headerText}>
                    {" "}
                    {data["environmentName"] === "Development" &&
                      data["environmentName"]}{" "}
                    {"  "}
                  </div>
                </Navbar.Text>
                <Navbar.Text>
                  <div className='nav-link'>
                    {false && !props.iframeLogin && (
                      <WixLoginBar
                        user={props.user}
                        callback={props.callback}
                      />
                    )}

                    {!props.iframeLogin && (
                      <div>
                        {loggedIn() && (
                          <figure className='after-login-user-info'>
                            <div className='user-icon'>
                              <FaCircleUser />
                            </div>
                            {/* <figcaption></figcaption> */}
                            <span
                              className={
                                afterLoginUserInfo ? "arow active" : "arow"
                              }
                              onClick={() =>
                                setAfterLoginUserInfo(!afterLoginUserInfo)
                              }>
                              <FaAngleDown />
                            </span>

                            {afterLoginUserInfo && (
                              <>
                                <div className='after-login-user-dropdown'>
                                  <figure className='user-info'>
                                    <h4>{member}</h4>
                                  </figure>
                                  <div className='dropdown--item'>
                                    <SuperAppButton
                                      className='dropdown-btn primary-gradient-btn'
                                      // hovertooltip={member}
                                      onClick={async () => {
                                        auth
                                          .signOut()
                                          .then(() => {
                                            // Session cookie invalidated
                                            console.log(
                                              "session cookie & storage gone"
                                            );
                                            //Cookies.remove("XRTSG");
                                            sessionStorage.removeItem(
                                              "sessionId"
                                            );
                                            localStorage.removeItem("token");
                                            setMember("");
                                            window.location = "/";
                                          })
                                          .catch((error) => {
                                            // An error happened
                                            console.error(error);
                                          });
                                        console.log("signing out");
                                      }}>
                                      Log Out
                                    </SuperAppButton>
                                  </div>
                                </div>
                                <div
                                  className='after-login-user-dropdown-overlay'
                                  onClick={() =>
                                    setAfterLoginUserInfo(false)
                                  }></div>
                              </>
                            )}
                          </figure>
                        )}
                        {!loggedIn() && (
                          <SuperAppButton
                            className='primary-gradient-btn'
                            size='sm'
                            onClick={() => {
                              setShowLoginButton(false);
                              props.history.push("/signin");
                            }}>
                            Login
                          </SuperAppButton>
                        )}
                      </div>
                    )}
                  </div>
                </Navbar.Text>
                {/*<Nav className="ml-4" style={style}> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      )}
      <div className={advertisement ? "advertisement-alert hide" : "advertisement-alert"}>
        <div className="custom-container">
          <div className="advertisement-alert-wrapper">
            <div className="alert-text">
              <p>Get <b>10%</b> Off Your first order!! Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div className="alert-action">
              <button type="button" className="closebtn" onClick={()=>setAdvertisement(!advertisement)}><IoMdClose /></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Header);

/*
                  <button onClick={() => {auth.signOut(); Cookies.remove("session"); window.location = "/"; }}>Sign Out</button>
      <Nav className="ml-2 mr-auto">
        <Button variant="primary" onClick={() => {props.history.push("/")}}>
            <FontAwesomeIcon icon={faHome} size="1x" />
            {" "}Home 
        </Button>
      </Nav>
              <Link to={"agents"}>
                <MenuButton title="Agents"></MenuButton>
              </Link>
{data.environmentName == 'Development' &&
}
*/
