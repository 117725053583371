import React, { useContext, useState } from 'react';

import RowMargin from "./RowMargin";
import GlobalContext from '../GlobalContext';

const Sidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState("Config");
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  const activeStyle = superAppStyle.sideBarActive;
  const inactiveStyle = superAppStyle.sideBarInactive;

  return (
    <div className='left-side-menu'>
      <ul className='menu-lists'>
        <li className='menu-item'><button className={activeMenu === 'Config' && 'active'} onClick={(e) => { setActiveMenu("Config"); props.callback("Config");}}>Admin Config</button></li>
        <li className='menu-item'><button className={activeMenu === 'Statistics' && 'active'} onClick={(e) => { setActiveMenu("Statistics"); props.callback("Statistics")}}>Statistics</button></li>
        <li className='menu-item'><button className={activeMenu === 'Plans' && 'active'} onClick={(e) => { setActiveMenu("Plans"); props.callback("Plans")}}>Plans</button></li>
        <li className='menu-item'><button className={activeMenu === 'Failed Messages' && 'active'} onClick={(e) => { setActiveMenu("Failed Messages"); props.callback("Failed Messages")}}>Failed Messages</button></li>
        <li className='menu-item'><button className={activeMenu === 'Feedback' && 'active'} onClick={(e) => { setActiveMenu("Feedback"); props.callback("Feedback")}}>Feedback</button></li>
        <li className='menu-item'><button className={activeMenu === 'Users' && 'active'} onClick={(e) => { setActiveMenu("Users"); props.callback("Users")}}>Users</button></li>
        <li className='menu-item'><button className={activeMenu === 'AdminAgents' && 'active'} onClick={(e) => { setActiveMenu("AdminAgents"); props.callback("AdminAgents")}}>Agents</button></li>
      </ul>
    </div>
  );
};

export default Sidebar;
