import React, { useEffect, useState } from "react";

import { withRouter } from "react-router";
import { useMyContext } from "../MyContext";
import { fetchSecure } from "../utils/utils";

import "../pages/Home.css";
import { Alert, Snackbar } from "@mui/material";

//Components

function ShowAgent(props) {
  const [agentURL, setAgentURL] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const myContext = useMyContext();

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    console.log("in ShowAgent useEffect");
    async function loadConfig() {
      const tokenResponse = await fetchSecure("/api/getToken");
      const token = await tokenResponse.json();
      if (token?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      setAgentURL(
        myContext["data"]["agentURL"] +
          "/chat/WWWW/" +
          props.agent_id +
          "/" +
          token["accessToken"]
      );
    }
    loadConfig();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      <iframe
        src={agentURL}
        className='myIframe'
        height='620'
        scrolling='no'></iframe>
    </div>
  );
}

export default withRouter(ShowAgent);
