async function fetchSecure(url, options) {
  const token = localStorage.getItem("token");

  if (options === undefined) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    if (!("headers" in options)) {
      options["headers"] = {};
      options.headers = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return fetch(url, options);
}

module.exports = { fetchSecure };
