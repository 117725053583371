import React from 'react';

const ChatBot = ({ iframeSrc }) => {

  return (
    <div>
      <div style={{ display: "block", position: "fixed", top: "110px", right: "20px", overflow: "hidden", border: "10px", borderRadius: "20px", zIndex: "999", height: "600px" }}>
        <iframe src={iframeSrc} width="300px" height="600px" scrolling="no" />
      </div>
    </div>
  );
};

export default ChatBot;
