import React, { useEffect, useState, useContext } from "react";

import { Row, Col, Form, Alert } from "react-bootstrap";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { fetchSecure } from "../utils/utils";

// generate select dropdown option list dynamically
function Options({ options }) {
  return options.map((option, index) => (
    <MenuItem key={index} value={option}>
      {option}
    </MenuItem>
  ));
}

export default function Registration(props) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    data: props.userDeep,
    status: "IDLE",
  });
  var numEmployeeOptions = ["1-9", "10-99", "100-999", "> 1000"];
  const [validationString, setValidationString] =
    useState(
      ""
    ); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };
  const formInvalid = (data) => {
    var invalid = false;
    var fieldString = "";

    if (!("question1" in data) || data["question1"].length === 0) {
      fieldString += "Question 1, ";
      invalid = true;
    }
    if (!("question2" in data) || data["question2"].length === 0) {
      fieldString += "Question 2, ";
      invalid = true;
    }
    if (!("question3" in data) || data["question3"].length === 0) {
      fieldString += "Question 3, ";
      invalid = true;
    }
    if (!("question4" in data) || data["question4"].length === 0) {
      fieldString += "Question 4, ";
      invalid = true;
    }
    if (invalid) {
      if (fieldString.length > 2)
        setValidationString(fieldString.substring(0, fieldString.length - 2));
      return true;
    } else return false;
  };

  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      console.log("in handleSubmit()");
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      if (formInvalid(formData.data) || form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE",
        });
      } else {
        event.preventDefault();
        setValidated(false);
        //formData.data["planName"] = "trial";
        if (false) {
          console.log(formData.data);
          return;
        }
        const response = await fetchSecure("/api/saveObject/users", {
          headers: { "Content-Type": "application/json" },
          method: "post",
          body: JSON.stringify(formData.data),
        });
        const data = await response.json();
        if (data.success) {
          console.log("in data success()");
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS",
          });
          props.setScreen(1);
          const response = await fetch("/api/verifyUser/" + data.id);
        } else {
          console.log("in data failure()");
          throw Error("Failed to register");
        }
      }
    } catch (e) {
      console.log(`Failed to add feedback`);
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setFormData({
        data: props.userDeep,
        status: "IDLE",
      });
    }
    loadComponent();
  }, [props.userDeep]);

  function displayForm(status) {
    if (status !== "SUCCESS") return true;
    return false;
  }

  return (
    <>
      <React.Fragment>
        {displayForm(formData.status) && (
          <>
            <p style={{color: 'var(--light-color)'}}>
              Thanks for your interest in the Gen Al Solutions Agent Builder.
              Before we give you full access for 30 days to try our amazing
              technology, we need to ask a couple of quick questions.
            </p>
            <Form className='login-form' noValidate validated={validated}>
              <Form.Group controlId='name' className='form-group'>
                <TextField
                  fullWidth
                  required
                  type='text'
                  name='question1'
                  placeholder='Enter company name'
                  onChange={handleInputChange}
                  label='Name'
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a company name
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='companyName' className='form-group'>
                <TextField
                  fullWidth
                  required
                  type='text'
                  name='question2'
                  placeholder='Enter your website'
                  onChange={handleInputChange}
                  label='Website'
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide your website URL (this allows us to help
                  configure your chatbot)
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='numEmployees' className='form-group'>
                <FormControl fullWidth>
                  <InputLabel id='no-emp-select-label' required>
                    Number Employees
                  </InputLabel>
                  <Select
                    labelId='no-emp-select-label'
                    name='question3'
                    label='Number Employees'
                    onChange={handleInputChange}>
                    <MenuItem value=''>
                      <em>No. of Employee</em>
                    </MenuItem>
                    {Options({
                      options: numEmployeeOptions,
                    })}
                  </Select>
                </FormControl>

                <Form.Control.Feedback type='invalid'>
                  Please provide an employee count
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='feedback' className='form-group'>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  label='Can you explain why you are interested in our solution?'
                  name='question4'
                  placeholder='Why you are interested in our solution?'
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide some reasons why you want to evaluate this
                  solution
                </Form.Control.Feedback>
              </Form.Group>
              <div className='submit-btn-lists'>
                <SuperAppButton
                  type='submit'
                  onClick={handleSubmit}
                  className='submit-btn primary-gradient-btn'>
                  Save
                </SuperAppButton>
              </div>
            </Form>
          </>
        )}
      </React.Fragment>
      {formData.status === "VALIDATION_FAILURE" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='danger'>
                Failed to save registration, required fields missing
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>
                Thank you for registering. Please check your inbox for an email
                from Gen AI Solutions, and click on the provided link to enable
                your trial
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
}
/*
Click <a href="#" onClick={() => window.location.href="/"}> to start your trial</a></Alert>
*/
