import React, { useState, useEffect } from 'react';
//import './App.css';
import { Button } from 'react-bootstrap';
import RowMargin from './RowMargin';

const ProductDisplay = (props) => (
  <section>
    <form action="/api/create-checkout-session" method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="planName" value={props.planName} />
      <Button id="checkout-and-portal-button" type="submit">
        Checkout
      </Button>
    </form>
  </section>
);

const SuccessDisplay = ({ sessionId, planName }) => {

  const capitalize = (str) => {
    if (typeof str === 'undefined') return "Undefined";
    if (str.length < 2) return str;
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  }
  return (
    <div className="consent-to-bill-msg-sec">
      <div className="custom-container">
        <div className="consent-to-bill-msg-wrapper">
          <h1>Subscription to {capitalize(planName)} successful!</h1>
          <div className="more-action">
          <form action="/api/create-portal-session" method="POST">
            <input
              type="hidden"
              id="session-id"
              name="session_id"
              value={sessionId}
            />
            <Button id="checkout-and-portal-button" type="submit" className="transparent-bg-white-btn action-btn">
              Manage your billing information
            </Button>
          </form>
          <Button id="continue-button" type="submit" onClick={() => window.location.href = "/"}  className="primary-gradient-btn action-btn">
            Continue
          </Button>
        </div>
        </div>
      </div>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Stripe(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [planName, setPlanName] = useState('');

  function extractValueFromURL(str, key) {
    var returnStr = "";
    if (typeof str.length === 'undefined') return ""
    if (str.indexOf(key) >= 0) {
      returnStr = str.substring(str.indexOf(key) + key.length + 1);
      let endPosition = returnStr.indexOf("&");
      if (endPosition >= 0)
        returnStr = returnStr.substring(0, returnStr.indexOf("&"));
    }
    return returnStr;
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    const query2 = (new URLSearchParams(window.location.search)) + "";
    console.log("query = " + JSON.stringify(query))
    console.log("query = " + JSON.stringify(query2))

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      setPlanName(extractValueFromURL(query2, "plan_name"));
    }

    if (false && query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (false && !success && message === '') {
    return <ProductDisplay planName={planName} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} planName={planName} />;
  } else {
    return <Message message={message} />;
  }
}

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);
/*
   <form action="http://localhost:5000/create-checkout-session" method="POST">
      {SLASH* Add a hidden field with the lookup_key of your Price *SLASH}
      <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
*/
