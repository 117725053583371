import DataTable, { createTheme } from "react-data-table-component";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "../../pages/Home.css";
import { useMyContext } from "../../MyContext";
import { fetchSecure } from "./utils";
import { Tooltip } from "@mui/material";
//Components
import UploadFile from "../UploadFile";
import CreateEdit from "./CreateEdit";
import DisplayDetails from "./DisplayDetails";
import UploadFileForImport from "../UploadFileForImport";
import SuperAppButton from "../SuperAppButton";
import { FaPlus } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { FaFileExport } from "react-icons/fa6";
import { LuImport } from "react-icons/lu";
import { RiCloseLargeLine } from "react-icons/ri";
import { Alert, Snackbar } from "@mui/material";

/* MORE SUPERTABLE code */
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(SuperAppButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function canCreate(props, config) {
  if (
    props.userDeep &&
    props.userDeep.roles &&
    props.userDeep.roles.toLowerCase().indexOf("create") >= 0
  )
    return true;

  if (
    config.security &&
    config.security.create &&
    config.security.create.indexOf("AllUsers") >= 0
  )
    return true;

  return false;
}

function isAdmin(props) {
  if (
    props.userDeep &&
    props.userDeep.roles &&
    props.userDeep.roles.toLowerCase().indexOf("admin") >= 0
  )
    return true;
  return false;
}
function canView() {}

function canDeleteRows(props, config) {
  if (
    config.security &&
    config.security.delete &&
    config.security.delete.indexOf("None") >= 0
  )
    /* KAD added Oct 7, 2024 to override personal flags */
    return false;

  if (
    props.userDeep &&
    props.userDeep.roles &&
    props.userDeep.roles.toLowerCase().indexOf("delete") >= 0
  )
    return true;

  if (
    config.security &&
    config.security.delete &&
    config.security.delete.indexOf("AllUsers") >= 0
  )
    return true;

  return false;
}

const Export = ({
  displayState,
  props,
  config,
  onExport,
  onImport,
  refresh,
  createRecord,
  deleteRows,
  selectedRows,
}) => (
  <>
    {displayState === "TABLE" && canCreate(props, config) && (
      <SuperAppButton
        onClick={(e) => createRecord()}
        className='filter-icon-btn'
        data-toggle2="custom-tooltip" custom-title2="Create">
        <FaPlus />
      </SuperAppButton>
    )}{" "}
    {props.depth === 1 && (
      <SuperAppButton onClick={(e) => refresh()} className='filter-icon-btn'
        data-toggle2="custom-tooltip" custom-title2="Refresh">
        <IoMdRefresh />
      </SuperAppButton>
    )}{" "}
    {displayState === "TABLE" && canDeleteRows(props, config) && (
      <SuperAppButton
        onClick={(e) =>
          window.confirm("Are you sure you wish to delete these objects?") &&
          deleteRows(selectedRows)
        }
        className='filter-icon-btn'
        data-toggle2="custom-tooltip" custom-title2="Delete">
        <MdDelete />
      </SuperAppButton>
    )}
    {props.depth === 1 && (
      <SuperAppButton
        onClick={(e) => onExport(e.target.value)}
        className='filter-icon-btn'
        data-toggle2="custom-tooltip" custom-title2="Export">
        <LuImport />
      </SuperAppButton>
    )}
    {props.depth === 1 &&
      displayState === "TABLE" &&
      canCreate(props, config) && (
        <SuperAppButton
          onClick={(e) => onImport(e.target.value)}
          className='filter-icon-btn import'
        data-toggle2="custom-tooltip" custom-title2="Import">
          <LuImport />
        </SuperAppButton>
      )}
  </>
);

const TrackingButtons = ({ displayState, props, createRecord }) => (
  <>
    {displayState === "TRACKING_TABLE" &&
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("create") >= 0 && (
        <SuperAppButton
          onClick={(e) => createRecord()}
          className='filter-icon-btn'
        data-toggle2="custom-tooltip" custom-title2="Create">
          <FaPlus />
        </SuperAppButton>
      )}
  </>
);

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className='search-div input-with-icon right-icon'>
    <TextField
      id='search'
      type='text'
      placeholder='Filter By Title'
      value={filterText}
      onChange={onFilter}
      className='form-control'
    />
    <ClearButton onClick={onClear} className='clear-btn right-icon'>
      <RiCloseLargeLine />
    </ClearButton>
  </div>
);

function exportJSONDump(object, fields, user) {
  /* CREATE TOKEN */
  //const payload = { userId: 123, role: 'admin' };
  /* TODO: Need to base the secretKey off something more generic than a SENDGRID_API_KEY */
  //const secretKey = await getSecret(process.env.GOOGLE_PROJECT_NUMBER, "SENDGRID_API_KEY");
  //const options = {expiresIn: '10'}
  //const expiresIn = '10m';

  //const token = jwt.sign(payload, secretKey);//, options;
  //console.log("token = " + token);
  //res.json({accessToken: token});
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "api/export/" + object + "/json/" + JSON.stringify(fields) + "/" + user
  );
  link.click();
  /*
    const response = await fetch("/api/export/" + object + "/json/XXX/" + props.user, {
      headers: { "Content-Type": "application/json", "Authorization": "XXXX" }
      method: "get",
    });
    const data = await response.json();
   */
}
function exportCSVDump(object, fields, user) {
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "api/export/" + object + "/csv/" + JSON.stringify(fields) + "/" + user
  );
  link.click();
}

/* MORE SUPERTABLE code */
/* MORE SUPERTABLE code */
/* MORE SUPERTABLE code */

export default function SuperAppObject(props) {
  const [openAlert, setOpenAlert] = useState(false);
  const { collection_name } = props;
  const [sortData, setSortData] = useState(
    props.config.defaultSort || { selector: "creationDate", asc: false }
  ); /* KAD removed React */
  const globalContextData = useMyContext();

  const [config, setConfig] = useState(props.config);
  const [deepConfig, setDeepConfig] = useState(
    props.deepConfig
  ); /* entire config tree..in case you need hierarchy of tabular data */
  const [selectedRows, setSelectedRows] = useState([]);
  const [lastDisplayState, setLastDisplayState] = useState("");
  const [whereValue, setWhereValue] = useState(props.drill_where_value || "");

  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE",
  });

  /* GENERIC CODE FROM SUPERTABLE */
  const [displayState, setDisplayState] = useState(props.display_state);
  const [actionType, setActionType] = useState("");
  const [object, setObject] = useState(collection_name);
  const [row, setRow] = useState({});
  const [data, setData] = useState(
    "input_data" in props ? props.input_data : []
  );
  const [originalData, setOriginalData] = useState({});
  const [columns, setColumns] = useState(config.table);
  const [depth, setDepth] = useState(props.depth); /* for nested objects */
  const [filterText, setFilterText] = useState(""); /* KAD removed React. */
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState(false); /* KAD removed React. */
  const filteredItems = data
    ? data.filter(
        (item) =>
          item[config.filterField] &&
          item[config.filterField]
            .toLowerCase()
            .includes(filterText.toLowerCase())
      )
    : [];
  const [validated, setValidated] =
    useState(false); /* for Bootstrap form validation */
  const [validationString, setValidationString] =
    useState(
      ""
    ); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */
  //const filteredItems = data;

  /* TODO: Can't have this hardcoded to agents here */

  if (props.collection === "agents") {
    props.showAgent = true;
  }

  if ("input_data" in props) {
    //console.log("KEVIN2 data passed in " + JSON.stringify(config, null, " "));
    //console.log("KEVIN2 data passed in " + JSON.stringify(props.input_data, null, " "));
    //console.log("KEVIN2 data passed in " + JSON.stringify(data, null, " "));
    //console.log("KEVIN2 data passed in " + JSON.stringify(props, null," "));
  }

  const callbackForCancelUploadFile = async () => {
    setDisplayState("DISPLAY");
    setActionType("DISPLAY");
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const callbackForCancelUploadFileForImport = async () => {
    setDisplayState("TABLE");
    setActionType("DISPLAY");
  };

  const callbackForUploadFileForImport = async (fileName) => {
    setTimeout(() => loadObjects(), 1000);
    setDisplayState("TABLE");
    setActionType("DISPLAY");
  };

  const callbackForUploadFile = async (fileNames) => {
    setDisplayState("DISPLAY");
    setActionType("DISPLAY");
    var dataToSave = { attachments: [] };
    if (!("attachments" in formData.data)) formData.data["attachments"] = [];
    for (let x = 0; x < fileNames.length; x++) {
      const fileName = fileNames[x];
      dataToSave["attachments"].push({
        fileName: fileName,
        shortFileName: fileName.substring(fileName.lastIndexOf("~~") + 2),
      });
      formData.data["attachments"].push({
        fileName: fileName,
        shortFileName: fileName.substring(fileName.lastIndexOf("~~") + 2),
      });
    }
    const response = await fetch(
      "/api/updateObject/" +
        config.collection +
        "/" +
        formData.data["id"] +
        "/" +
        props.user,
      {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(dataToSave),
      }
    );
    const data = await response.json();

    if (data.success) {
      setTimeout(() => loadObject(formData.data["id"]), 2000);
    }
  };

  /* BEGIN TABLE FUNCTIONS */
  /* delete all the selected Rows */
  const deleteRows = (selectedRows) => {
    selectedRows.forEach((item) => {
      var doc = { row: item };
      deleteRow(doc);
    });
    /* reload after 2 seconds to make sure all the deleted Rows are done, could take a bit */
    setTimeout(() => {
      loadObjects();
      setSelectedRows([]);
    }, 2000);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const createRecord = () => {
    const maxCount =
      "max" +
      collection_name.substring(0, 1).toUpperCase() +
      collection_name.substring(1);
    if (maxCount in props.userDeep && data.length > props.userDeep[maxCount]) {
      alert(
        "You have exceeded your maximum " +
          props.collection_name +
          " of " +
          props.userDeep[maxCount] +
          ".  Please upgrade your package to a higher level if you need more agents"
      );
    } else {
      setFormData({
        data: {},
        status: "IDLE",
      });
      setLastDisplayState(displayState);
      setDisplayState("CREATE_EDIT");
      setActionType("CREATE");
    }
  };
  const backToBASIC = () => {
    setDisplayState("BASIC_TABLE");
    setActionType("DISPLAY");
  };

  const refresh = () => {
    loadObjects();
  };

  const handleRowClicked = (row) => {
    //console.log("handle row clicked - " + JSON.stringify(row));
    setRow(row);
    /* TODO - not sure why there are 2 things setting the Form Data..probably just some frustration in the past */
    var form = {
      data: row,
      status: "IDLE",
    };
    setFormData({
      data: row,
      status: "IDLE",
    });
    setDisplayState("DISPLAY");
    setActionType("EDIT");
  };

  const handleFileClicked = (row) => {
    //console.log("handle file clicked - " + JSON.stringify(row));
    window.open("api/getFile/" + row.fileName);
  };

  const handleRowClickedDisplay = (row) => {
    //console.log("handle row clicked - " + JSON.stringify(row));
    setRow(row);
    var form = {
      data: row,
      status: "IDLE",
    };
    //console.log("form = " + JSON.stringify(form));
    setFormData({
      data: row,
      status: "IDLE",
    });
    setDisplayState("DISPLAY");
    setActionType("DISPLAY");
  };

  const loadObject = async (id) => {
    console.log(
      "IN loadObject - api/getObject/" + config.collection + "/" + id
    );
    try {
      await fetch("api/getObject/" + config.collection + "/" + id)
        .then((response) => response.json())
        .then((data) => {
          //console.log("data = " + JSON.stringify(data));
          data["id"] = id; // TODO this is kind of weird...certain functions in server.js put the id in the payload automatically...this one doesn't
          setFormData({
            data: data,
            status: "IDLE",
          });
        });
    } catch (e) {
      console.log(`Failed to loadObject`);
      console.log(e);
      console.log(e.stack);
    }
  };

  const loadObjects = async () => {
    var additionalCommandLineArg = "";
    if ("flags" in config && config.flags.indexOf("filter_by_user") >= 0) {
      additionalCommandLineArg = "/where/userIds/array-contains/" + props.user;
    }

    if ("remote_id" in props) {
      additionalCommandLineArg = "/" + props.remote_id;
    }
    await fetchSecure(
      "api/getObjects/" + config.collection + additionalCommandLineArg
    )
      .then((response) => response.json())
      .then((data) => {
        /* the array is at a lower level */
        //console.log("KEVIN1 " + data)
        if (data?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        if (typeof props.sub_array !== "undefined") {
          /* KAD taking this out to confirm that this is not needed 
          const kevin = data.map((object, index) => {
            object.config["row"] = index;
            object.config["id"] = object.id;
            object.config["creationDate"] = object.creationDate;
            object.config["updateDate"] = object.updateDate;
            object.config["user"] = object.user;
            return object.config;
          });

          setOriginalData(data);
          //console.log("KEVIN1 - " + JSON.stringify(kevin));
          setData(kevin);
*/
        } else {
          setData(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("IN HERE SuperAppObject useEffect (props.id) - " + props.id);
    async function loadAgent() {
      if ("id" in props && props.id && props.id.length > 0) {
        await loadObject(props.id);
      }
    }
    loadAgent();
  }, [props.id]);

  useEffect(() => {
    if (
      "input_data" in props &&
      props.input_data &&
      props.input_data.length > 0
    ) {
      //console.log("using input_data - " + JSON.stringify(props.input_data));
      //console.log(props.input_data);
      setData(props.input_data);
    }
  }, [props.input_data]);

  useEffect(() => {
    function handleStatusChange(status) {
      console.log("Doing handleStatusChange - " + status);
    }
    console.log("IN HERE useEFFECT");
    if (data && data.length === 0 && !("input_data" in props)) {
      //console.log("KEVIN1 getting database data " + JSON.stringify(config));
      var additionalCommandLineArg = "";
      if ("remote_id" in props)
        additionalCommandLineArg = "/" + props.remote_id;

      if ("drill_where_field" in props && "drill_where_value" in props) {
        additionalCommandLineArg =
          "/where/" +
          props.drill_where_field +
          "/==/" +
          props.drill_where_value;
      }
      if ("flags" in config && config.flags.indexOf("filter_by_user") >= 0) {
        additionalCommandLineArg =
          "/where/userIds/array-contains/" + props.user;
      }

      fetchSecure(
        "api/getObjects/" + config.collection + additionalCommandLineArg
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.code?.includes("auth")) {
            setOpenAlert(true);
          }
          /* TODO: Think this has to go..thie sub_array code ..not used anymore */
          if (typeof props.sub_array !== "undefined") {
            const kevin = data.map((object, index) => {
              object.config["id"] = object.id;
              object.config["row"] = index;
              object.config["creationDate"] = object.creationDate;
              object.config["updateDate"] = object.updateDate;
              object.config["user"] = object.user;
              return object.config;
            });
            setOriginalData(data);
            setData(kevin);
          } else setData(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      if (
        "input_data" in props &&
        props.input_data &&
        props.input_data.length > 0
      ) {
        //console.log("using input_data - " + JSON.stringify(props.input_data));
        //console.log(props.input_data);
        setData(props.input_data);
      }

      //if ("input_data" in props && props.input_data && props.input_data.length === 0)
      //console.log("input_data empty - " + JSON.stringify(config));
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      console.log("Doing Cleanup");
    };
  }, []);

  /* GENERIC CODE FROM SUPERTABLE */
  /* GENERIC CODE FROM SUPERTABLE */
  /* GENERIC CODE FROM SUPERTABLE */

  const handleSelectChange = (state) => {
    //console.log('Selected Rows: ', state.selectedRows);
    setSelectedRows(state.selectedRows);
  };

  const onColorChange = (value, name) => {
    console.log("onColorChange " + name + "," + value);
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  /* delete an individual row */
  const deleteRow = (doc) => {
    if (doc.row.locked === true) {
      window.confirm("Record " + doc.row.id + " not deleted.  It is locked");
      return;
    }
    fetchSecure(
      "api/deleteObject/" +
        object +
        "/" +
        encodeURIComponent(doc.row.id) +
        "/" +
        props.user,
      {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setDisplayState("TABLE");
      });
  };

  const uploadFileForImport = () => {
    setDisplayState("UPLOAD_FILE_FOR_IMPORT");
    setActionType("UPLOAD_FILE_FOR_IMPORT");
  };

  const formInvalid = () => {
    var invalid = false;
    var fieldString = "";
    config.fields.map((field) => {
      var showField = true;
      if (typeof field.conditional !== "undefined") {
        //     conditional: "AGENT_TYPE == DIALOGFLOW",
        const values = field.conditional.split(" ");
        if (formData.data[values[0]] != values[2]) showField = false;
      }
      if (
        field.required &&
        showField &&
        (!formData.data[field.name] || formData.data[field.name].length === 0)
      ) {
        console.log("Form invalid");
        fieldString += field.display_name + ", ";
        invalid = true;
      }
    });
    if (invalid) {
      setValidationString(fieldString.substring(0, fieldString.length - 2));
      return true;
    } else return false;
  };

  /* get an array back from the lower depth, and save to database */
  const topLevelSave = async (field, object) => {
    //console.log("TLS = " + field);
    //console.log("TLS = " + JSON.stringify(object));
    formData.data[field] = object;
    //console.log("TLS = " + JSON.stringify(formData.data));
    if (props.depth === 1) {
      const response = await fetch("/api/saveObject/" + config.collection, {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(formData.data),
      });
      const data = await response.json();
      if (data.success) {
        //console.log(data);
        setFormData({
          data: { ...formData.data },
          status: "SUCCESS",
        });
        setDisplayState("X");
        //console.log(JSON.stringify(formData));

        /* Go back and update the Tracking records */
        setTimeout(() => {
          loadObject(data.id);
        }, 1000);

        props.scroll_to_top();
        setDisplayState("DISPLAY");
        setActionType("DISPLAY");
      } else {
        throw Error("Failed to add new " + field);
      }
    }
  };

  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      /* adding another custom level of validation for objects */
      let valid = true;
      if (typeof props.validate_object !== "undefined") {
        valid = props.validate_object(formData.data);
        //console.log("valid = " + JSON.stringify(valid));
        formData.data["status"] = valid.status;
      }
      if (formInvalid() || form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE",
        });
      } else {
        setValidated(false);
        var dataToSave = {};
        if ("row" in formData.data && typeof props.sub_array !== "undefined") {
          /* this is an existing row when a subarray is involved  */
          const row = formData.data["row"];
          //console.log("ROW in Submit = " + row);
          originalData[row][props.sub_array] = formData.data;
          //console.log(JSON.stringify(originalData[row]));
          dataToSave = originalData[row];
        } else if (
          !("row" in formData.data) &&
          typeof props.sub_array !== "undefined"
        ) {
          /* this is a new row when a subarray is involved */
          dataToSave[props.sub_array] = formData.data;
        } else {
          dataToSave = formData.data;
        }
        dataToSave["user"] =
          props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
        const response = await fetch("/api/saveObject/" + config.collection, {
          headers: { "Content-Type": "application/json" },
          method: "post",
          //ORIG JUL 15, 2024body: JSON.stringify(formData.data)
          body: JSON.stringify(dataToSave),
        });
        const data = await response.json();
        if (data.success) {
          formData.data["id"] =
            data.id; /* KAD added this to populate the primary key in the UI after saving for the first time */
          //console.log(data);
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS",
          });
          setDisplayState("X");
          //console.log(JSON.stringify(formData));

          /* Go back and update the Tracking records */
          setTimeout(() => {
            loadObject(data.id);
          }, 1000);

          props.scroll_to_top();
          setDisplayState("DISPLAY");
          setActionType("DISPLAY");
        } else {
          throw Error("Failed to add new " + config.name);
        }
      }
    } catch (e) {
      console.log(
        `Failed to add new ` + config.name + `: ${formData.data.agentType}`
      );
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  const actionsMemo = useMemo(
    () => (
      <Export
        displayState={displayState}
        props={props}
        config={config}
        createRecord={createRecord}
        refresh={refresh}
        deleteRows={deleteRows}
        selectedRows={selectedRows}
        onExport={() =>
          exportJSONDump(config.collection, config.exportFields, props.user)
        }
        onImport={uploadFileForImport}
      />
    ),
    [data, config, selectedRows, props]
  );

  const actionsMemoTracking = useMemo(() => (
    <TrackingButtons
      displayState={displayState}
      props={props}
      createRecord={createRecord}
    />
  ));

  createTheme("superappobject", props.style.theme);

  function showExport(field, formData) {}

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      {displayState === "UPLOAD_ATTACHMENT" && (
        <UploadFile
          filePrefix={collection_name
            .concat("~~")
            .concat(formData.data.id)
            .concat("~~")}
          collection_name={collection_name}
          id={formData.data.id}
          user_id={formData.data.user}
          agent_type={formData.data.agentType}
          callback={callbackForUploadFile}
          handleUploadFileCancel={callbackForCancelUploadFile}
        />
      )}
      {displayState === "UPLOAD_FILE_FOR_IMPORT" && (
        <UploadFileForImport
          collection_name={collection_name}
          user_id={props.user}
          callback={callbackForUploadFileForImport}
          handleUploadFileCancel={callbackForCancelUploadFileForImport}
        />
      )}
      {displayState === "TRACKING_TABLE" && (
        <div
          style={{
            fontSize: "14px",
            marginLeft: "-15px",
            marginRight: "-15px",
          }}>
          <DataTable
            title=''
            columns={columns}
            data={filteredItems}
            noContextMenu
            noHeader
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            dense
            persistTableHead
            defaultSortFieldId={sortData.selector}
            defaultSortAsc='true'
            onRowClicked={props.on_row_click}
            customStyles={props.style.customStyles}
            actions={actionsMemoTracking}
            theme='superappobject'
          />
        </div>
      )}
      {displayState === "FILE_TABLE" && (
        <div
          style={{
            fontSize: "14px",
            marginLeft: "-15px",
            marginRight: "-15px",
          }}>
          <DataTable
            title=''
            columns={columns}
            data={filteredItems}
            noContextMenu
            noHeader
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            dense
            persistTableHead
            defaultSortFieldId={sortData.selector}
            defaultSortAsc='true'
            onRowClicked={handleFileClicked}
            customStyles={props.style.customStyles}
            actions={actionsMemoTracking}
            theme='superappobject'
          />
        </div>
      )}
      {displayState === "BASIC_TABLE" && (
        <>
          <DataTable
            title={props.title}
            columns={columns}
            data={filteredItems}
            noContextMenu
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            dense
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            defaultSortFieldId={sortData.selector}
            defaultSortAsc={sortData.asc}
            onRowClicked={props.on_row_click}
            customStyles={props.style.customStyles}
            paginationPerPage={config.otherTableOptions.paginationPerPage}
            actions={actionsMemo}
            theme='superappobject'
          />
        </>
      )}
      {displayState === "STATS_TABLE" && (
        <>
          <DataTable
            title={props.title}
            columns={columns}
            data={filteredItems}
            noContextMenu
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            dense
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead
            onSelectedRowsChange={handleSelectChange}
            defaultSortFieldId={sortData.selector}
            defaultSortAsc={sortData.asc}
            onRowClicked={handleRowClicked}
            customStyles={props.style.customStyles}
            paginationPerPage={config.otherTableOptions.paginationPerPage}
            theme='superappobject'
          />
        </>
      )}
      {displayState === "TABLE" && (
        <>
          <DataTable
            title={props.title}
            columns={columns}
            data={filteredItems}
            noContextMenu
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead
            onSelectedRowsChange={handleSelectChange}
            defaultSortFieldId={sortData.selector}
            defaultSortAsc={sortData.asc}
            onRowClicked={handleRowClicked}
            actions={actionsMemo}
            customStyles={props.style.customStyles}
            paginationPerPage={config.otherTableOptions.paginationPerPage}
            theme='superappobject'
          />
        </>
      )}
      {displayState === "DRILL" && (
        <>
          <div>
            <SuperAppButton onClick={(e) => backToBASIC()}>Back</SuperAppButton>
          </div>
          <SuperAppObject
            depth={props.depth + 1}
            style={props.style}
            title={props.drill_title}
            config={props.drill_config}
            user={props.user}
            userDeep={props.userDeep}
            collection_name={props.drill_collection}
            drill_where_field='NAICS Code'
            drill_where_value={whereValue}
            display_state='BASIC_TABLE'
            on_row_click={handleRowClickedDisplay}
          />
        </>
      )}
      {displayState === "CREATE_EDIT" && (
        <CreateEdit
          config={config}
          all_objects={data}
          this_object={formData.data}
          actionType={actionType}
          setDisplayState={setDisplayState}
          setActionType={setActionType}
          loadObject={loadObject}
          loadObjects={loadObjects}
          originalData={originalData}
          style={props.style}
          scroll_to_top={props.scroll_to_top}
          user={props.user}
          userDeep={props.userDeep}
          setFormData={setFormData}
          top_level_save={props.top_level_save}
          depth={props.depth}
          sub_table={props.sub_table}
          input_data={props.input_data}
        />
      )}
      {displayState === "DISPLAY" &&
        formData.data &&
        Object.keys(formData.data).length !== 0 && (
          <DisplayDetails
            config={config}
            all_objects={data}
            this_object={formData.data}
            setDisplayState={setDisplayState}
            setActionType={setActionType}
            loadObjects={loadObjects}
            loadObject={loadObject}
            originalData={originalData}
            style={props.style}
            scroll_to_top={props.scroll_to_top}
            user={props.user}
            setFormData={setFormData}
            deepConfig={deepConfig}
            callBackForUploadFile={callbackForUploadFile}
            showAgent={props.showAgent}
            canEdit={props.canEdit}
            depth={props.depth}
            sub_table={props.sub_table}
            top_level_save={topLevelSave}
            userDeep={props.userDeep}
          />
        )}
    </>
  );
}
