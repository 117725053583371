import React, { useEffect, useState, useContext } from "react";

import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";
import { Row, Col, Form, Alert } from "react-bootstrap";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import { fetchSecure } from "../utils/utils";
import {
  Snackbar,
  Alert as MuiAlert,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { IoMdAttach } from "react-icons/io";

// generate select dropdown option list dynamically
function Options({ options }) {
  return options.map((option, index) => (
    <MenuItem key={index} value={option}>
      {option}
    </MenuItem>
  ));
}

export default function Feedback(props) {
  const globalData = useContext(GlobalContext);
  const myContext = useMyContext();
  const [validated, setValidated] = useState(false);
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [formData, setFormData] = useState({
    data: props.data,
    status: "IDLE",
  });
  //var helpOptions = ["Provide Feedback", "Report a Bug", "Ask a question"];
  //var helpOptions2 = myContext["data"]["requestTypes"]
  //console.log(helpOptions2)

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleInputChange = (e) => {
    console.log("Select change");

    const target = e.target;
    const localFileNames = [];
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        value = e.target.files;
        for (let x = 0; x < value.length; x++) {
          console.log("File Name = " + value[x].name);
          if (value[x].name.indexOf("~~") >= 0) {
            resetFileInput();
            window.alert(
              "File names can't have tildas (~) in the name. Please rename your file and upload again"
            );
            return;
          }
          localFileNames.push(
            "feedback~~" + props.userDeep["id"] + "~~" + value[x].name
          );
        }
        setFileNames(localFileNames);
        console.log("files = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(name);
    if (name === "fileName") {
      setFileName("feedback~~" + props.userDeep["id"] + "~~" + value.name);
    }
    console.log(name, value);
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmit = async () => {
    try {
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      const uploadData = new FormData();
      console.log(formData.data);
      Object.keys(formData.data).forEach((key) => {
        console.log("KEY = " + key);
        if (key === "fileName") {
          for (let x = 0; x < formData.data[key].length; x++)
            uploadData.append(
              key + "-" + x,
              formData.data[key][x],
              fileNames[x]
            );
        } else uploadData.append(key, formData.data[key]);
      });
      uploadData.append("id", "Unknown");
      uploadData.append("userId", props.user);
      uploadData.append("objectType", "Feedback");
      console.log("uploadData = " + JSON.stringify(uploadData));

      const response = await fetchSecure("/api/upload", {
        method: "post",
        body: uploadData,
      });

      const data = await response.json();
      if (data?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      if (response.status === 200) {
        setFormData({
          data: data,
          status: "SUCCESS",
        });
        resetFileInput();

        /* callback to save the agent object after 3 seconds */
        setTimeout(async function () {
          if (!("attachments" in formData.data))
            formData.data["attachments"] = [];
          for (let x = 0; x < fileNames.length; x++) {
            const fileName = fileNames[x];
            formData.data["attachments"].push({
              fileName: fileName,
              shortFileName: fileName.substring(fileName.lastIndexOf("~~") + 2),
            });
          }
          console.log(JSON.stringify(formData.data));
          delete formData.data["fileName"];
          var dataToSave = formData.data;
          dataToSave["user"] =
            props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
          const response = await fetchSecure("/api/saveObject/feedback", {
            headers: { "Content-Type": "application/json" },
            method: "post",
            body: JSON.stringify(dataToSave),
          });
          const data = await response.json();
          if (data?.code?.includes("auth")) {
            setOpenAlert(true);
          }
          if (data.success) {
            if (globalData.debug) console.log(data);
            setFormData({
              data: { ...formData.data },
              status: "SUCCESS",
            });
            if (globalData.debug) console.log(JSON.stringify(formData));
          } else {
            throw Error("Failed to add new " + props.config.name);
          }
        }, 3000);
      } else {
        setFormData({
          data: data,
          status: "ERROR",
        });
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  const handleInputChangeOrig = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmitOrig = async (event) => {
    try {
      const form = event.currentTarget;
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE",
        });
      } else {
        setValidated(false);
        var dataToSave = formData.data;
        dataToSave["user"] =
          props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
        const response = await fetchSecure("/api/saveObject/feedback", {
          headers: { "Content-Type": "application/json" },
          method: "post",
          body: JSON.stringify(dataToSave),
        });
        const data = await response.json();
        if (data?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        if (data.success) {
          if (globalData.debug) console.log(data);
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS",
          });
          if (globalData.debug) console.log(JSON.stringify(formData));
        } else {
          throw Error("Failed to add new " + props.config.name);
        }
      }
    } catch (e) {
      console.log(`Failed to add feedback`);
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <MuiAlert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </MuiAlert>
      </Snackbar>
      {formData.status === "IDLE" && (
        <React.Fragment>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId='requestType' className='form-group'>
              <FormControl fullWidth>
                <InputLabel id='req-type-select-label' required>
                  Request Type
                </InputLabel>
                <Select
                  labelId='req-type-select-label'
                  name='requestType'
                  value={formData.data?.requestType}
                  label='Request Type'
                  onChange={handleInputChange}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {Options({
                    options: myContext["data"]["requestTypes"] || [],
                  })}
                </Select>
              </FormControl>
              <Form.Control.Feedback type='invalid'>
                Please provide a Request Type
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='feedback' className='form-group'>
              <TextField
                multiline
                rows={3}
                fullWidth
                variant='outlined'
                label='Provide the Details For Your Request'
                name='feedback'
                value={formData.data?.feedback}
                placeholder='Enter your comment/request/question'
                onChange={handleInputChange}
                required
              />

              <Form.Control.Feedback type='invalid'>
                Please provide some comments
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='uploadFile' className='form-group'>
              <Form.Label>Upload File(<small>s</small>)</Form.Label>
              <div className='file-uploader-s1'>
                <div className='icon'>
                  <IoMdAttach />
                </div>
                <div className='text'>Insert a file</div>
                <Form.Control
                  key={fileInputKey}
                  type='file'
                  name='fileName'
                  placeholder='Select a file or files'
                  className='form-control'
                  onChange={handleInputChange}
                  accept='pdf'
                  multiple
                />
              </div>
            </Form.Group>
            <div className='submit-btn-lists'>
              <SuperAppButton
                type='submit'
                className='submit-btn primary-gradient-btn'>
                Save
              </SuperAppButton>
            </div>
          </Form>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>Successfully added</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
}
