import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "@mui/material";

import GlobalContext from '../GlobalContext';

export default function SuperAppButton({ children, ...props }) {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  {/* return <Button style={superAppStyle.btnPrimary} props={...props} ></Button>; */}
  const customStyles = superAppStyle.btnPrimary;
  const hoverToolTip = props.hovertooltip;
  return (
    <Tooltip title={hoverToolTip} >
    <Button style={{ ...customStyles, ...props.style }} {...props}>
      {children}
    </Button>
    </Tooltip>
  );
};
/*
    <Button style={{ ...customStyles, ...props.style }} {...props}>
*/

