/* https://dev.wix.com/docs/rest/business-management/payments/wix-payments-provider/transactions/introduction */

import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import Registration from "../components/Registration";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import NewPlanTable from "../components/NewPlanTable";
import Stripe from "../components/Stripe";
import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";
import { fetchSecure } from "../utils/utils";
import "./Home.css";
/* STRIPE STUFF */
import { FaChevronLeft } from "react-icons/fa";
import { Alert, Snackbar } from "@mui/material";

//const stripePromise = loadStripe("pk_test_51Q0CbMRpm2Dt6FkHg4EP7bfClb11EFqtBSWOeKqgWhAzSfwKYNG8oZ9NSUwdIFRKemJzx3iEZA3qKcOvJMtbbwdv00gONaoWp0");
//https://www.mdfaisal.com/blog/how-to-integrate-stripe-with-a-react-application

export default function Home(props) {
  const myContext = useMyContext();
  const [screen, setScreen] = useState(props.screen || 1);
  const [currentPlanName, setCurrentPlanName] = useState(
    props.userDeep.planName
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [allPlansData, setAllPlansData] = useState([]);
  const [newPlanData, setNewPlanData] = useState([]);
  const [activeTrialCount, setActiveTrialCount] = useState(0);
  const maxTrialsAllowed = 3;
  //const [checkOutURL, setCheckOutURL] = useState([]);
  const [stripeSessionId, setStripeSessionId] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [trialNumDaysLeft, setTrialNumDaysLeft] = useState(
    props.userDeep.trialLength || 100
  );
  const [open, setOpen] = useState(false);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [userData, setUserData] = useState({
    data: props.userDeep,
    status: "IDLE",
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  function extractValueFromURL(str, key) {
    var returnStr = "";
    if (typeof str.length === "undefined") return "";
    if (str.indexOf(key) >= 0) {
      returnStr = str.substring(str.indexOf(key) + key.length + 1);
      let endPosition = returnStr.indexOf("&");
      if (endPosition >= 0) returnStr = returnStr.substring(0, endPosition);
    }
    return returnStr;
  }
  function getUser() {
    fetchSecure("/api/getUser/" + props.user)
      .then((userResponse) => userResponse.json())
      .then((data) => {
        setUserData({
          data: data,
          status: "IDLE",
        });
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      try {
        const response = await fetchSecure("/api/getPlans");
        const planDataArray = await response.json();
        if (planDataArray?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        setAllPlansData(planDataArray);

        const activeTrialCountResponse = await fetchSecure(
          "/api/getActiveTrialCount"
        );
        const activeTrialCount = await activeTrialCountResponse.json();
        if (activeTrialCount?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        setActiveTrialCount(activeTrialCount["activeTrialCount"]);

        //console.log("session = " + props.userDeep.setStripeSessionId)
        try {
          if (
            props.userDeep.stripeSessionId &&
            props.userDeep.stripeSessionId.length > 0
          ) {
            setStripeSessionId(props.userDeep.stripeSessionId);
          }
        } catch (e) {
          console.error(e);
        }

        //console.log("userDeep = " + JSON.stringify(props.userDeep));
        //console.log("user = " + props.user);

        /*
                if (Object.keys(props.userDeep).length === 0 && props.user.length > 0) {
                  const response = await fetchSecure("/api/getUser/" + props.user);
                  const data = await response.json();
          console.log(data);
                  if (Object.keys(data).length === 0 && props.user.length > 0) {
                     setTimeout(getUser, 2000);
                  }
                  else {
                     setUserData({
                       data: data,
                       status: "IDLE"
                     });
                  }
               }   
         */
        setUserData({
          data: props.userDeep,
          status: "IDLE",
        });
        var d1 = new Date(props.userDeep.creationDate),
          d2 = new Date();
        setTrialNumDaysLeft(
          props.userDeep.trialLength - Math.floor((d2 - d1) / 60000 / 24 / 60)
        );
        setCurrentPlanName(props.userDeep.planName);

        const query = new URLSearchParams(window.location.search) + "";
        if (
          query.indexOf("screen") >= 0 &&
          typeof props.userDeep !== "undefined" &&
          props.userDeep &&
          "userId" in props.userDeep
        ) {
          setCurrentPlanName(extractValueFromURL(query, "plan_name"));
          setStripeSessionId(extractValueFromURL(query, "session_id"));
          console.log("session = " + extractValueFromURL(query, "session_id"));
          await confirmBilling(
            extractValueFromURL(query, "session_id"),
            props.userDeep
          );
          setScreen(4);
        }
      } catch (e) {
        console.error(e);
        console.error(e.stack);
      }
    }
    loadComponent();
  }, [props.userDeep]);

  async function prevScreen() {
    setScreen(screen - 1);
  }
  async function nextScreen() {
    setScreen(screen + 1);
  }

  async function updateBilling() {
    console.log("in updateBilling");
    const body = {};
    body["planName"] = newPlanData["planName"];
    body["stripeCustomerId"] = props.userDeep["stripeCustomerId"];
    console.log("in updateBilling - " + newPlanData["planName"]);
    console.log("in updateBilling - " + props.userDeep["userId"]);
    const response2 = await fetchSecure("/api/updateSubscription", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(body),
    });
    const data2 = await response2.json();
    if (data2?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    if (data2.success) {
      await confirmBilling("", props.userDeep);
    }
  }

  async function confirmBilling(sessionId, user) {
    if (user && "planName" in user) {
      user["subscribed"] = user["proposedPlan"]["subscribed"];
      user["planName"] = user["proposedPlan"]["planName"];
      user["maxAgents"] = user["proposedPlan"]["maxAgents"];
      user["maxMessageCount"] = user["proposedPlan"]["maxMessageCount"];
      user["maxFileSize"] = user["proposedPlan"]["maxFileSize"];
      user["maxFAQLength"] = user["proposedPlan"]["maxFAQLength"];
      user["subscriptionDate"] = new Date().toISOString();
      if (typeof sessionId !== "undefined" && sessionId.length > 0) {
        user["stripeSessionId"] = sessionId;
        //userData.data["stripeCustomerId"] = stripeCustomerId;
        user["paymentInfoLoaded"] = true;
      }
      user["billingLive"] = true;
    }
    console.log("user = " + JSON.stringify(user));

    if ("id" in user) {
      /* save the form */
      const response2 = await fetchSecure("/api/saveObject/users", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(user),
      });
      const data2 = await response2.json();
      if (data2?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      if (data2.success) {
        // alert("Plan was updated to " + capitalize(user["planName"]) + "");
        setScreen(3);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }

  async function redirectToCheckOut() {
    let body = {};
    body["planName"] = newPlanData["planName"];
    body["userId"] = props.userDeep["userId"];
    console.log(body["planName"]);
    const response3 = await fetchSecure("/api/create-checkout-session", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(body),
    });
    const data3 = await response3.json();
    if (data3?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    setStripeCustomerId(data3["stripeCustomerId"]);
    window.location.href = data3["url"];
  }
  async function selectPlan(planName) {
    /* first load the specifics of the plan that was selected */
    /* 
           const response = await fetchSecure("/api/getObjects/plans/where/planName/==/" + planName) 
           const planDataArray = await response.json();
           const planData = planDataArray[0];
      */
    const response = await fetchSecure("/api/getPlanByName/" + planName);
    const planData = await response.json();
    if (planData?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    setNewPlanData(planData);

    const response1 = await fetchSecure("/api/getAgentCount/" + props.user);
    const responseData = await response1.json();
    if (responseData?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    const numAgents = responseData["numAgents"];
    if (numAgents > planData["maxAgents"]) {
      window.alert(
        "You cannot move to this plan as you have created too many agents.  Please either delete the agents you don't want or pick a plan that supports this many agents. If you want to keep your work, you can use the Export button on the Agents page to download a file which you could import in at a later date"
      );
      return;
    }

    /* Add the data into the form */
    userData.data["proposedPlan"] = {};
    if (planName !== "trial") {
      /* can only set subscribed if you are not in trial */
      userData.data["proposedPlan"]["subscribed"] = true;
    } else {
      /* this code should never get executed if things are working properly */
      userData.data["proposedPlan"]["subscribed"] = false;
    }
    userData.data["proposedPlan"]["planName"] = planName;
    userData.data["proposedPlan"]["maxAgents"] = planData["maxAgents"];
    userData.data["proposedPlan"]["maxMessageCount"] =
      planData["maxMessageCount"];
    userData.data["proposedPlan"]["maxFileSize"] = planData["maxFileSize"];
    userData.data["proposedPlan"]["maxFAQLength"] = planData["maxFAQLength"];

    /* save the form */
    if ("id" in userData.data) {
      const response2 = await fetchSecure("/api/saveObject/users", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(userData.data),
      });
      const data = await response2.json();
      if (data?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      if (data.success) {
        setScreen(screen + 1);
      } else {
        alert("Proposed Plan was not updated");
      }
    } else {
      alert("Proposed Plan was not updated");
    }
  }
  const capitalize = (str) => {
    if (typeof str === "undefined") return "Undefined";
    if (str.length < 2) return str;
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const AgentList = () => {
    return (
      <ul>
        <li>
          FAQ Agent - an agent that will answer frequently asked questions
        </li>
        <li>
          Document QA Agent - an agent that will answer questions about uploaded
          PDF or DOCX documents
        </li>
        <li>
          Website QA Agent - an agent that will answer questions about scanned
          websites that you maintain
        </li>
        <li>
          Dialogflow Agent - an agent that will connect to a Dialogflow or
          Vertex AI Agent that you created in Google
        </li>
        <p></p>
      </ul>
    );
  };
  function canConnectToStripe() {
    if (myContext["data"]["environmentName"] === "Development") return true;
    if (myContext["data"]["adminEmail1"] === props.user) return true;
    if (myContext["data"]["adminEmail2"] === props.user) return true;
    if (myContext["data"]["adminEmail3"] === props.user) return true;
    if (myContext["data"]["adminEmail4"] === props.user) return true;
    if (myContext["data"]["adminEmail5"] === props.user) return true;
    if (myContext["data"]["adminEmail6"] === props.user) return true;
    return false;
  }

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({
      data: { ...userData.data, [name]: value },
      status: "IDLE",
    });
  };

  function canRegisterForTrial() {
    console.log("active Trial Count " + activeTrialCount);
    console.log("Max Trial Allowd " + maxTrialsAllowed);
    if (activeTrialCount > maxTrialsAllowed) {
      return false;
    }
    return true;
  }

  function buttonName() {
    if (newPlanData["planName"] === "freemium") return "Accept";
    if (
      newPlanData["planName"] !== "freemium" &&
      props.userDeep.paymentInfoLoaded === false
    )
      return "Checkout";
    if (
      newPlanData["planName"] !== "freemium" &&
      !("paymentInfoLoaded" in props.userDeep)
    )
      return "Checkout";
    if (
      newPlanData["planName"] !== "freemium" &&
      props.userDeep.paymentInfoLoaded === true
    )
      return "Confirm Plan Change";
  }

  return (
    <React.Fragment>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error' variant='filled'>
          Failed to update plan!
        </Alert>
      </Snackbar>

      {userData &&
        userData.data.planName === "unregistered" &&
        !canRegisterForTrial() &&
        screen === 1 && (
          <>
            <div className='consent-to-bill-msg-wrapper for-info-card mt'>
              <h3>Gen AI Trial Limit Exceeded</h3>
              <p>
                Thank you for your interest in trying our solution.
                Unfortunately, we are temporarily out of trial licenses. If
                you'd like to try our solution, you can sign up for the Freemium
                plan right now, or if you need more access to our broader
                solutions, you can click the button below and we will alert you
                when more licenses become available
              </p>
            </div>
            {/* TODO...finish this code <SuperAppButton>Alert me when I can start my trial</SuperAppButton> */}
            <NewPlanTable
              showButtons={true}
              currentPlanName={currentPlanName}
              selectPlan={selectPlan}
              dontShowTrial={true}
            />

            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {userData &&
        userData.data.planName === "unregistered" &&
        canRegisterForTrial() &&
        screen === 1 && (
          <div className='authentication-page'>
            <div className='image-container'>
              <img src='/images/login-register-pic1.png' />
            </div>
            <div className='form-sec'>
              <div className='form-container'>
                <div className='headerPart'>
                  <div className='center-logo'>
                    <img src='/images/genAIAgentBuilder-logo.png' alt='' />
                  </div>
                </div>
                <div className='bodyPart'>
                  <Registration
                    userDeep={props.userDeep}
                    setScreen={setScreen}
                  />
                </div>
              </div>
            </div>

            {/* {props.showLoginErrorMessage && <Footer />} */}
          </div>
        )}
      {props.user &&
        props.userDeep &&
        typeof userData.data["planName"] !== "undefined" &&
        props.userDeep.planName !== "trial" &&
        props.userDeep.planName !== "unregistered" &&
        screen === 1 && (
          <>
            <div className='custom-container'>
              <div className='consent-to-bill-msg-wrapper for-info-card mt'>
                <h3>
                  You are currently subscribed to the{" "}
                  {capitalize(userData.data["planName"])} plan.
                </h3>
                <h4>
                  If you need more capacity, feel free to upgrade to one of our
                  bigger packages (Coming Soon!!):
                </h4>

                <div
                  className='paragraph-text'
                  dangerouslySetInnerHTML={{
                    __html: myContext["data"]["marketingMessage"],
                  }}
                />
              </div>
              <NewPlanTable
                showButtons={true}
                currentPlanName={currentPlanName}
                selectPlan={selectPlan}
              />
              {/* <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan} /> */}
              <RowMargin />
            </div>
            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {props.user &&
        props.userDeep &&
        props.userDeep.planName === "trial" &&
        screen === 1 && (
          <>
            <div className='consent-to-bill-msg-wrapper for-info-card mt'>
              <h3>Welcome to your Agent/Chatbot Builder Trial</h3>
              <div>
                {props.userDeep.trialOver && (
                  <p>
                    <font color='red'>
                      Your trial has expired. Tomorrow, we will archive all of
                      your agents in preparation for deletion in 30 days. If
                      you'd like to purchase a plan, you can review the list
                      below and pick one. If you need more time, please send us
                      a note at{" "}
                      <a href='mailto:info@genaisolutions.ai'>
                        info@genaisolutions.ai
                      </a>{" "}
                      to request more time. Please specify how much time you
                      need, and why you need the additional time.
                    </font>
                  </p>
                )}
                {!props.userDeep.trialOver && (
                  <>
                    <p>
                      You have {trialNumDaysLeft} days left to try out all our
                      different types of agents:
                      <RowMargin />
                      <AgentList />
                      <RowMargin />
                      During this trial, you will be allowed to create up to{" "}
                      {props.userDeep.maxAgents} agents of any type; however,
                      you will NOT be able to put these agents into any
                      production sites. The software provides a way for you to
                      see and interact with your chatbot/agent. You will also be
                      limited by the size of the data that you are able to
                      store. Other than these limitations, the agents should be
                      fully functional. We also have some demos on the main
                      website which can aid in your evaluation.
                    </p>
                    <p>
                      If you have done your evaluation, please use the following
                      table to evaluate our various plans. Click on the "Buy
                      Now" button for the plan you want to choose.
                    </p>
                  </>
                )}
                {/* <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan} /> */}
              </div>
            </div>
            <NewPlanTable
              showButtons={true}
              currentPlanName={currentPlanName}
              selectPlan={selectPlan}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: myContext["data"]["marketingMessage"],
              }}
            />
            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {props.user && screen === 2 && (
        <>
          <div className='consent-to-bill-msg-sec'>
            <div className='custom-container'>
              <div className='consent-to-bill-msg-wrapper'>
                <h1>Consent to Bill </h1>
                <h4>
                  {" "}
                  You have selected the{" "}
                  <strong>{capitalize(newPlanData["planName"])}</strong> plan.
                  To checkout and enter your billing information, please click
                  the <strong>"{buttonName()}"</strong> button. Your
                  subscription will continue until you cancel the service. Yout
                  billing will be not be prorated so if you don't cancel before
                  your billing day of month, you will have to pay the entire
                  month.
                </h4>

                <div className='more-action'>
                  <Button
                    name='Submit'
                    onClick={prevScreen}
                    className='transparent-bg-white-btn action-btn'>
                    Back
                  </Button>{" "}
                  {newPlanData["planName"] === "freemium" && (
                    <Button
                      name='Submit'
                      onClick={() => confirmBilling("", userData.data)}
                      className='primary-gradient-btn action-btn'>
                      Accept
                    </Button>
                  )}
                  {newPlanData["planName"] !== "freemium" &&
                    props.userDeep.paymentInfoLoaded === false && (
                      <Button
                        name='Submit'
                        onClick={redirectToCheckOut}
                        className='primary-gradient-btn action-btn'>
                        Checkout
                      </Button>
                    )}
                  {newPlanData["planName"] !== "freemium" &&
                    !("paymentInfoLoaded" in props.userDeep) && (
                      <Button
                        name='Submit'
                        onClick={redirectToCheckOut}
                        className='primary-gradient-btn action-btn'>
                        Checkout
                      </Button>
                    )}
                  {newPlanData["planName"] !== "freemium" &&
                    props.userDeep.paymentInfoLoaded === true && (
                      <Button
                        name='Submit'
                        onClick={updateBilling}
                        className='primary-gradient-btn action-btn'>
                        Confirm Plan Change
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 3 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Billing Screen</h1>
                <h4>Subscription to {capitalize(newPlanData["planName"])} plan successful!</h4>


                {newPlanData["planName"] !== "freemium" && (
                  <form action="/api/create-portal-session" method="POST">
                    <input
                      type="hidden"
                      id="session-id"
                      name="session_id"
                      value={stripeSessionId}
                    />
                    <div className="more-action" >
                      <Button id="checkout-and-portal-button" type="submit" className="action-btn primary-gradient-btn">
                        View your billing information
                      </Button>{" "}
                    </div>
                  </form>
                )}
                <div className="more-action" >
                  <Button
                    id="continue-button"
                    type="submit"
                    className="action-btn primary-gradient-btn"
                    onClick={() => (window.location.href = "/")}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 4 && (
        <>
          {/* <RowMargin />
          <h1 style={{ paddingLeft: "100px" }}>Billing Screen</h1> */}
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <Stripe />
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 5 && (
        <>
          <div className='consent-to-bill-msg-sec'>
            <div className='custom-container'>
              <div className='consent-to-bill-msg-wrapper'>
                <h1>Consent to Bill</h1>
                <h4>
                  Congratulations! You are now configured to go LIVE with 1
                  chatbot/agent into production (WHAT TO DO ABOUT ALL THE OTHER
                  CHAT BOTS THEY CREATED???)
                </h4>
              </div>
            </div>
          </div>

          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      <NotLoggedInMessage {...props} />
    </React.Fragment>
  );
}
/*
    Current Plan = {capitalize(currentPlanName)}
          <form>
            <select name="planName" onChange={handleInputChange}>
              <option selected value=""></option>
              { currentPlanName !== "freemium" && <option value="freemium">Freemium</option>}
              { currentPlanName !== "silver" && <option value="silver">Silver</option>}
              { currentPlanName !== "gold" && <option value="gold">Gold</option>}
              { currentPlanName !== "platinum" && <option value="platinum">Platinum</option>}
            </select>
          </form> 
          <Button name="Submit" onClick={handleClick}>Submit</Button>
    <WixSubscriptions />
    <Elements stripe={stripePromise}>
      <MyCheckoutForm />
    </Elements>
             <div><Button name="Submit" onClick={confirmBilling}>Accept</Button></div>
in screen 2
          {props.userDeep.billingLive === true &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={() => updateBilling()}>Accept</Button></div>
          }
          {props.userDeep.billingLive === false &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={getBillingURL}>Proceed to Billing Information</Button></div>
          }
  async function updateBilling() {
     * CONTACT BILLING FOR NEXT MONTH *
     await confirmBilling(); * use same method to change the plan *
     setScreen(4);
  }
         <div style={{ height: "50%", backgroundImage: `url("/robot.jpg")`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >
      </div>
*/
