//import Header from "../comp/header";
//import Footer from "../comp/footer";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { auth } from "../firebase/config";
import { Form, Alert } from "react-bootstrap";

import RowMargin from "../components/RowMargin";
import SuperAppButton from "../components/SuperAppButton";

import { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GlobalContext from "../GlobalContext";
import { FaChevronLeft } from "react-icons/fa6";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import {
  updateProfile,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

import { useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { useMyContext } from "../MyContext";
import { TextField } from "@mui/material";

//import Loading from "../comp/Loading";

//import Error404 from "./error404";

export default function Signup(props) {
  //const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [hasError, setHasError] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [userName, setUserName] = useState();
  const [phone, setPhone] = useState();
  const [isCheckedTc, setIsCheckedTc] = useState(false);
  const myContext = useMyContext();

  useEffect(() => {
    if (user) {
      //navigate("/");
      //window.location = "/"
    }
  });

  //5MIfPjTuGBgiEsWlEqFjOljPB3b2

  // Sign Up Function For The SignUp Button
  const signUpBTN = (eo) => {
    eo.preventDefault();
    if (password !== password2) {
      setHasError(true);
      setErrorMessage("Passwords need to match");
    } else {
      setHasError(false);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          console.log(user);
          sendEmailVerification(auth.currentUser).then(() => {
            console.log("Email verification sent!");
            setShowForm(false);
            setAlertMessage(
              "An Email with a link has been sent to your email to confirm"
            );
            // ...
          });

          updateProfile(auth.currentUser, {
            displayName: userName,
            phoneNumber: phone,
          })
            .then(() => {
              // Profile updated!
              // ...
            })
            .catch((error) => {
              // An error occurred
              // ...
            });
          console.log("Done");

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          setHasError(true);
          switch (errorCode) {
            case "auth/invalid-email":
              setErrorMessage("Invalid Email");

              break;

            case "auth/invalid-credential":
              setErrorMessage("Invalid Email");
              break;

            case "auth/weak-password":
              setErrorMessage("Weak Password");
              break;

            case "auth/email-already-in-use":
              setErrorMessage("Email already in use");
              break;

            default:
              setErrorMessage("Please Check Your Email/Password....");
              break;
          }
          // ..
        });
    }
  };

  //Loading

  //Not Sign In

  //Sign in Without email verfication

  // sign in && email verified email  => navigate()
  if (loading) {
    {
      /* <Loading/> */
    }
    return <>Loading</>;
  }
  if (error) {
    {
      /* return <Error404/>; */
    }
    return <>Error</>;
  }

  if (true) {
    return (
      <>
        {/* <Helmet> */}
        <title>Sign Up Page </title>
        {/* </Helmet> */}
        <main>
          <div className='form-container'>
            <div className='headerPart'>
              <h2>Create A New Account</h2>
              <h4>Please Signup to access the tool</h4>
            </div>
            <div className='bodyPart'>
              {showForm && (
                <Form className='login-form' onSubmit={signUpBTN}>
                  <Form.Group controlId='formBasicName' className='form-group'>
                    <TextField
                      fullWidth
                      label='Name'
                      type='text'
                      placeholder='Enter your name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId='formBasicEmail' className='form-group'>
                    <TextField
                      fullWidth
                      label='Email'
                      type='email'
                      placeholder='Enter your email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicPassword'
                    className='form-group'>
                    <div className='input-with-icon right-icon'>
                      <TextField
                        fullWidth
                        label='Password'
                        type={showPassword ? "text" : "password"}
                        placeholder='Enter your password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />

                      <button
                        type='button'
                        onClick={() => setShowPassword(!showPassword)}
                        className='right-icon'>
                        {" "}
                        {showPassword ? <VscEye /> : <VscEyeClosed />}{" "}
                      </button>
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicPassword2'
                    className='form-group'>
                    <div className='input-with-icon right-icon'>
                      <TextField
                        fullWidth
                        label='Re-enter Password'
                        type={showPassword ? "text" : "password"}
                        placeholder='Enter your password again'
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        required
                      />
                      <button
                        type='button'
                        onClick={() => setShowPassword(!showPassword)}
                        className='right-icon'>
                        {" "}
                        {showPassword ? <VscEye /> : <VscEyeClosed />}{" "}
                      </button>
                    </div>
                    <div className='leftRight-text '>
                      <div className='checkagree'>
                        <div className='checkcol'>
                          <input
                            onChange={(e) => setIsCheckedTc(e.target.checked)}
                            type='checkbox'
                            className='theme-checkbox-s1'
                          />
                        </div>
                        <label
                          dangerouslySetInnerHTML={{
                            __html: myContext["data"]["privacyPolicy"],
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <div className='submit-btn-lists'>
                    <SuperAppButton
                      disabled={!isCheckedTc}
                      onClick={(eo) => {
                        signUpBTN(eo);
                      }}
                      className='submit-btn primary-gradient-btn'
                      type='submit'>
                      Create Account
                    </SuperAppButton>
                  </div>
                </Form>
              )}
              {alertMessage && <Alert variant='success'>{alertMessage}</Alert>}
              {hasError && <Alert variant='danger'>{errorMessage}</Alert>}
              {false && user && (
                <Alert variant='success'>Successfully Logged In</Alert>
              )}
              <div className='more-text'>
                Already have an account?{" "}
                <div
                  className='text-link cursor-pointer'
                  onClick={(e) => props.callback("signin")}>
                  Sign In
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  } else {
    <div>User already signed up</div>;
  }
}
/*
          <form action="">
            <input
              onChange={(eo) => {
                setuserName(eo.target.value);
              }}
              id="username"
              placeholder="User Name..."
              type="text"
              required
            />
            <input
              onChange={(eo) => {
                setemail(eo.target.value);
              }}
              id="email"
              placeholder="Email..."
              type="email"
              required
            />
            <input
              onChange={(eo) => {
                setPhone(eo.target.value);
              }}
              id="phonenumber"
              placeholder="Phone..."
              type="number"
              required
            />
            <input
              onChange={(eo) => {
                setpassword(eo.target.value);
              }}
              id="password"
              placeholder="Password..."
              type="password"
              required
            />
            <button
              onClick={(eo) => {
                signUpBTN(eo)
              }}
            >
              Sign Up
            </button>
            {hasError && <p>{errorMessage}</p>}
          </form>
*/
