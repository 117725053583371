import React, { useEffect, useState, useContext } from "react";

//Components
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import SuperAppObject from "../components//superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { Alert, Box, Snackbar } from "@mui/material";

export default function Statistics(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [count, setCount] = useState(0);
  const [failedMessages, setFailedMessages] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const loadStats = async (userId, agentId) => {
    try {
      await fetchSecure("api/getStats/" + userId + "/" + agentId)
        .then((response) => response.json())
        .then((data) => {
          if (data?.code?.includes("auth")) {
            setOpenAlert(true);
          }
          setFailedMessages(data["failed_messages"]);
          setCount(data["count"]);
        });
    } catch (e) {
      console.log(`Failed to loadStats`);
      console.log(e);
      console.log(e.stack);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  function getConfig() {
    var configObj = {};
    try {
      configObj["failed_messages"] = {
        collection: "input_data",
        defaultSort: "kevin",
        name: "Messages",
        defaultSort: { selector: 5, asc: false },
        showLikes: false,
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "None",
          view: "AllUsers",
        },
        flags: "",
        fields: [
          {
            placeholder: "",
            display_name: "User ID",
            name: "user_id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Agent ID",
            name: "agent_id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Request",
            name: "request",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Response",
            name: "response",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Date",
            name: "log_timestamp",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [
          {
            selector: (row) => row.user_id,
            name: "User ID",
            sortable: true,
            width: "10%",
          },
          {
            selector: (row) => row.agent_id,
            name: "Agent ID",
            sortable: true,
            width: "10%",
          },
          {
            selector: (row) => row.request,
            name: "Request",
            wrap: true,
            sortable: true,
            width: "20%",
          },
          {
            selector: (row) => row.response,
            name: "Response",
            wrap: true,
            sortable: true,
            wrap: true,
            width: "40%",
          },
          {
            selector: (row) => row.creationDate,
            name: "Creation Date",
            hide: "md",
            sortable: true,
            sortOrder: "desc",
            minWidth: "10%",
            sortFunction: (rowB, rowA) => {
              // Perform ASCII comparison
              if (typeof rowB.creationDate === "undefined") return true;
              return rowB.creationDate.localeCompare(rowA.creationDate);
            },
            cell: (row) =>
              row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
          },
        ],
        filterField: "response",
        exportFields: ["request", "response"],
        otherTableOptions: { paginationPerPage: 10 },
      };
    } catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }
  const config = getConfig();

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      await loadStats(props.user, props.agentId);
    }
    loadComponent();
  }, []);

  return (
    <React.StrictMode>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      <Box ref={containerRef} className='table-listing-page-wrapper'>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            <h3>Statistics</h3>
            <h4>Total Log Messages: {count}</h4>
            {/* <Paper style={superAppStyle.cardStyle} elevation={8}> */}
            <SuperAppObject
              style={superAppStyle}
              title='Failed Messages - Use this data to improve your training data and answer more questions'
              config={config["failed_messages"]}
              user={props.user}
              userDeep={props.userDeep}
              collection_name='messages'
              input_data={failedMessages}
              display_state='STATS_TABLE'
              canEdit='false'
            />
            {/* </Paper> */}
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </Box>
    </React.StrictMode>
  );
}
