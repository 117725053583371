
import React, { useState } from 'react';

/* NEW MENU STUFF */
import MenuItem from "@mui/material/MenuItem";
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
/* NEW MENU STUFF */
/* NEW MENU STUFF */

function DrawMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuSelect = (e, value, field) => {
        e.preventDefault();

        props.setFormData({
            data: { ...props.data, [field]: value },
            status: "IDLE"
        });

        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        if (anchorEl === null)
            setAnchorEl(event.currentTarget);
        else
            setAnchorEl(null);
    };

    return (
        <>
            &nbsp;
            <button
                type="button"
                style={{ "border": "none", "backgroundColor": "transparent" }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}>
                (Show/Hide Samples)
            </button>
            &nbsp;
            <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleMenuClick}
                open={Boolean(anchorEl)}
            >
                {props.field.samples.map(sample => {
                    return (
                        <MenuItem key={sample} onClick={(e) => { handleMenuSelect(e, sample, props.field.name) }}>
                            {sample.startsWith("https://") &&
                                <img src={sample}></img>
                            }
                            {!sample.startsWith("https://") &&
                                sample
                            }
                        </MenuItem>
                    )
                })
                }
            </Menu>
        </>
    )
}
/* END NEW MENU STUFF */
export default DrawMenu;
