import React, { useState, useEffect } from "react";

/* NEW IMAGE STUFF */
//import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import GalleryGrid from "./GalleryGrid";
import UploadFileForImages from "./UploadFileForImages";
import { Gallery } from "react-grid-gallery";
/* NEW IMAGE STUFF */
import { fetchSecure } from "../utils/utils";
import { Alert, Snackbar } from "@mui/material";

const PopupGallery = (props) => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const defaultImages = [{ src: "/Magic_wand.gif" }];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    console.log("IN HERE CreateEdit useEffect - ");
    async function loadImages() {
      const response = await fetchSecure(
        "/api/getFileURLs/" + props.userDeep.id
      );

      var data = await response.json();
      if (data?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      console.log(data);
      data = data.concat(defaultImages);
      setImages(data);
    }
    loadImages();
  }, []);

  const reloadImages = async () => {
    console.log("in reloadImages");
    const response = await fetchSecure("/api/getFileURLs/" + props.userDeep.id);

    var data = await response.json();
    if (data?.code?.includes("auth")) {
      setOpenAlert(true);
    }
    data = data.concat(defaultImages);
    setImages(data);
    setOpen(true);
  };
  const handleSelect = (index, item, event) => {
    console.log(images[index]);
    props.setFormData({
      data: { ...props.data, [props.field.name]: images[index].src },
      status: "IDLE",
    });
    setOpen(false);
  };

  /*
      <Button style={{ height: '10px' }} variant="outlined" onClick={handleClickOpen}>
        Open Gallery
      </Button>
*/
  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </Alert>
      </Snackbar>
      <div style={{ cursor: "pointer" }} onClick={handleClickOpen}>
        Open Gallery
      </div>
      <Dialog open={open}>
        <div
          style={{
            cursor: "pointer",
            textAlign: "right",
            paddingRight: "10px",
          }}
          onClick={handleClose}>
          X
        </div>
        <DialogTitle>Image Gallery</DialogTitle>
        <UploadFileForImages
          filePrefix={props.userDeep.id.concat("/")}
          id={props.userDeep.id}
          user_id={props.userDeep.userId}
          callback={reloadImages}
        />
        <Gallery
          defaultContainerWidth={1000}
          images={images}
          onSelect={handleSelect}
        />
      </Dialog>
    </div>
  );
};

export default PopupGallery;
