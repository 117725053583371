import React, { useEffect, useState, useContext } from "react";

import GlobalContext from "../GlobalContext";
import { Row, Col, Form, Alert } from "react-bootstrap";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import { fetchSecure } from "../utils/utils";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

// generate select dropdown option list dynamically

export default function SystemConfig(props) {
  const globalData = useContext(GlobalContext);

  const [validated, setValidated] = useState(false);
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [formData, setFormData] = useState({
    data: props.userDeep,
    status: "IDLE",
  });
  const [openAlert, setOpenAlert] = useState(false);

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const localFileNames = [];
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        value = e.target.files;
        for (let x = 0; x < value.length; x++) {
          console.log("File Name = " + value[x].name);
          if (value[x].name.indexOf("~~") >= 0) {
            resetFileInput();
            window.alert(
              "File Names can't tildas (~) in the name. Please rename your file and upload again"
            );
            return;
          }
          localFileNames.push("feedback~~" + value[x].name);
        }
        setFileNames(localFileNames);
        console.log("files = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(name);
    if (name === "fileName") {
      setFileName("feedback~~" + value.name);
    }
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmit = async () => {
    try {
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      const uploadData = new FormData();
      console.log(formData.data);
      Object.keys(formData.data).forEach((key) => {
        console.log("KEY = " + key);
        if (key === "fileName") {
          for (let x = 0; x < formData.data[key].length; x++)
            uploadData.append(
              key + "-" + x,
              formData.data[key][x],
              fileNames[x]
            );
        } else uploadData.append(key, formData.data[key]);
      });
      uploadData.append("id", props.id);
      uploadData.append("user_id", props.user_id);
      uploadData.append("object", "users");
      console.log("uploadData = " + JSON.stringify(uploadData));

      /* TODO..need to clean up how to save File AND then save object ...too much duplicated code */
      if (false) {
        const response = await fetchSecure("/api/upload", {
          method: "post",
          body: uploadData,
        });

        const data = await response.json();
        if (response.status === 200) {
          setFormData({
            data: data,
            status: "SUCCESS",
          });
          resetFileInput();

          /* callback to save the agent object after 3 seconds */
          setTimeout(async function () {
            if (!("attachments" in formData.data))
              formData.data["attachments"] = [];
            for (let x = 0; x < fileNames.length; x++) {
              const fileName = fileNames[x];
              formData.data["attachments"].push({
                fileName: fileName,
                shortFileName: fileName.substring(
                  fileName.lastIndexOf("~~") + 2
                ),
              });
            }
            console.log(JSON.stringify(formData.data));
            delete formData.data["fileName"];
            var dataToSave = formData.data;
            dataToSave["user"] =
              props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
            const response = await fetchSecure("/api/saveObject/users", {
              headers: { "Content-Type": "application/json" },
              method: "post",
              body: JSON.stringify(dataToSave),
            });
            const data = await response.json();
            if (data.success) {
              if (globalData.debug) console.log(data);
              setFormData({
                data: { ...formData.data },
                status: "SUCCESS",
              });
              if (globalData.debug) console.log(JSON.stringify(formData));
            } else {
              throw Error("Failed to add new " + props.config.name);
            }
          }, 3000);
        } else {
          setFormData({
            data: data,
            status: "ERROR",
          });
        }
      } else {
        var dataToSave = formData.data;
        dataToSave["user"] =
          props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
        const response = await fetchSecure("/api/saveObject/users", {
          headers: { "Content-Type": "application/json" },
          method: "post",
          body: JSON.stringify(dataToSave),
        });
        const data = await response.json();
        if (data?.code?.includes("auth")) {
          setOpenAlert(true);
        }
        if (data.success) {
          if (globalData.debug) console.log(data);
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS",
          });
          if (globalData.debug) console.log(JSON.stringify(formData));
        } else {
          throw Error("Failed to add new " + props.config.name);
        }
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  const handleInputChangeOrig = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <MuiAlert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </MuiAlert>
      </Snackbar>
      {formData.status === "IDLE" && (
        <React.Fragment>
          <div className='authentication-page center-form'>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className='form-sec'>
              <div className='form-container w447px p-0'>
                <div className='headerPart'>
                  <h2>Privacy Policy</h2>
                  <h4>
                    Enter your privacy policy including html if you need links
                    to other pages
                  </h4>
                </div>
                <div className='bodyPart'>
                  <Form.Group controlId='privacyPolicy' className='form-group'>
                    <Form.Label>Privacy Policy</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='privacyPolicy'
                      value={formData.data["privacyPolicy"]}
                      onChange={handleInputChange}
                      placeholder='Enter your privacy policy including html if you need links to other pages'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide some comments
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className='submit-btn-lists'>
                    <SuperAppButton
                      variant='primary'
                      type='submit'
                      className='submit-btn primary-gradient-btn'>
                      Save
                    </SuperAppButton>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>Successfully added</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
}
