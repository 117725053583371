import React, { useEffect, useState, useContext } from "react";


//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import UserAdminSidebar from "../components/UserAdminSidebar";
import Billing from "../components/Billing";
import Statistics from "../components/Statistics";
import SystemConfig from "../components/SystemConfig";
import GlobalContext from '../GlobalContext';

export default function UserAdmin(props) {
  const [screen, setScreen] = useState("Billing");

  useEffect(() => {
    async function loadAdminData() {
      console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      <div className="admin-page-layout">
        <div className="custom-container">
          <div className="left-side-menu">
            <UserAdminSidebar callback={setScreen} />
          </div>
          {props.user && props.user.length > 0 && props.showLoginErrorMessage &&
            <div className="right-content">
              <>
                {screen === 'Billing' &&
                  <Billing user={props.user} userDeep={props.userDeep} />
                }
                {screen === 'Statistics' &&
                  <Statistics user={props.user} userDeep={props.userDeep} />
                }
                {screen === 'System Config' &&
                  <SystemConfig user={props.user} userDeep={props.userDeep} />
                }
              </>
            </div>
          }
        </div>
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
          {screen ==='Billing' && 
              <Billing user={props.user} userDeep={props.userDeep} />
          }
*/
