import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";
import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

export default function Billing(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [count, setCount] = useState(0);
  const [stripeSessionId, setStripeSessionId] = useState(props.userDeep.stripeSessionId);


  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
    <React.StrictMode>
      <>
        <>
          <>
            {props.user && props.user.length > 0 &&
              <>
                <h3>Billing Information</h3>
                {!stripeSessionId &&
                  <h4>You are not billing anything yet</h4>
                }
                {stripeSessionId &&
                  <>
                    <form action="/api/create-portal-session" method="POST">
                      <input
                        type="hidden"
                        id="session-id"
                        name="session_id"
                        value={stripeSessionId}
                      />
                      <SuperAppButton id="checkout-and-portal-button" type="submit" className="primary-gradient-btn">
                        View your billing information
                      </SuperAppButton>
                      <RowMargin />
                      <SuperAppButton id="checkout-and-portal-button" type="submit" className="primary-gradient-btn">
                        Cancel your service
                      </SuperAppButton>
                      <RowMargin />
                    </form>
                    <SuperAppButton id="change-your-service" className="primary-gradient-btn" onClick={(e) => { window.location = '/' }}>
                      Change your service
                    </SuperAppButton>
                  </>
                }
              </>
            }
            <NotLoggedInMessage {...props} />
            {props.showLoginErrorMessage && <Footer />}
          </>
        </>
      </>
    </React.StrictMode>
  );
}
