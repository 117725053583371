import React, { useState } from "react";

import { Container, Row, Col, Form, Alert } from "react-bootstrap";

//Components
import TopMargin from "../components/TopMargin";
import RowMargin from "../components/RowMargin";
import Loading from "../components/Loading";
import SuperAppButton from "../components/SuperAppButton";
import { fetchSecure } from "../utils/utils";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

export default function UploadFile(props) {
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE",
  });

  const [openAlert, setOpenAlert] = useState(false);

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const localFileNames = [];
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        //value = e.target.files[0];
        value = e.target.files;
        for (let x = 0; x < value.length; x++) {
          console.log("File Name = " + value[x].name);
          /* ALLOW DASHES NOW Oct 20, 2024 
          if (value[x].name.indexOf("-") >= 0) {
             resetFileInput();
             window.alert("File Names can't have dashes. Please rename your file and upload again");
             return;
          }
*/
          localFileNames.push(props.filePrefix + value[x].name);
        }
        setFileNames(localFileNames);
        console.log("files = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(props.filePrefix);
    console.log(name);
    if (props.filePrefix && name === "fileName") {
      setFileName(props.filePrefix + value.name);
    }
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmit = async () => {
    for (let x = 0; x < fileNames.length; x++) {
      if (
        !fileNames[x].toLowerCase().endsWith("pdf") &&
        !fileNames[x].toLowerCase().endsWith("docx")
      ) {
        setFormData({
          ...formData,
          message: "You can only upload PDF or DOCX files",
          status: "ERROR",
        });
        return;
      }
    }

    alert(
      'Please note that this process can take a while, so please don\'t abandon this screen if the "spinner" is showing. Depending on the file size, it could take as long as 10 minutes before the file has been loaded and indexed by the system.'
    );
    try {
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      const uploadData = new FormData();
      console.log(formData.data);
      Object.keys(formData.data).forEach((key) => {
        console.log("KEY = " + key);
        if (fileName) {
          for (let x = 0; x < formData.data[key].length; x++)
            //uploadData.append(key, formData.data[key], fileName);
            uploadData.append(
              key + "-" + x,
              formData.data[key][x],
              fileNames[x]
            );
        } else uploadData.append(key, formData.data[key]);
      });
      uploadData.append("id", props.id);
      uploadData.append("userId", props.user_id);
      uploadData.append("objectType", props.collection_name);
      uploadData.append(
        "agentType",
        props.agent_type
      ); /* TODO: DO NOT DELETE... used by server.js to know how to handle the file....need to find a non-hardcoded way to deal with */
      console.log("uploadData = " + JSON.stringify(uploadData));

      const response = await fetchSecure("/api/upload", {
        method: "post",
        body: uploadData,
      });

      const data = await response.json();
      if (data?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      if (response.status === 200) {
        setFormData({
          data: data,
          status: "SUCCESS",
        });
        resetFileInput();

        /* callback to save the agent object after 3 seconds */
        setTimeout(function () {
          if (props.callback) props.callback(fileNames);
        }, 3000);
      } else {
        setFormData({
          data: data,
          status: "ERROR",
        });
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  return (
    <Container>
      <Snackbar
        open={openAlert}
        autoHideDuration={1000}
        onClose={handleCloseAlert}>
        <MuiAlert onClose={handleCloseAlert} severity='error' variant='filled'>
          Session expired! Please login again
        </MuiAlert>
      </Snackbar>
      <Row>
        <Col>
          <TopMargin />
          <Form>
            <Form.Group controlId='uploadFile'>
              <Form.Label>Upload PDF/DOCX File(s)</Form.Label>
              <Form.Control
                key={fileInputKey}
                type='file'
                name='fileName'
                placeholder='Select a file or files'
                className='form-control'
                onChange={handleInputChange}
                accept='pdf'
                multiple
              />
            </Form.Group>
            <Form.Group></Form.Group>
            <RowMargin />
            <SuperAppButton
              variant='primary'
              type='button'
              onClick={handleSubmit}>
              Upload
            </SuperAppButton>
            &nbsp;
            <SuperAppButton
              variant='primary'
              type='button'
              onClick={props.handleUploadFileCancel}>
              Cancel
            </SuperAppButton>
          </Form>
        </Col>
      </Row>
      {formData.status === "SUBMITTED" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "ERROR" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='danger'>
                {formData && "message" in formData
                  ? formData.message
                  : "Failed to upload file, please try again"}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>
                {formData && "message" in formData
                  ? formData.message
                  : "Successfully uploaded file!"}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
}
