import React, { useEffect, useState } from "react";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import { useMyContext } from "../MyContext";

/*
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
*/
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Help(props) {
  const myContext = useMyContext();

  useEffect(() => {
    //console.log("IN HERE Help UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  return (
    <div className='privacyComponent-sec'>
      <div className='custom-container'>
        {false && props.user && props.user.length > 0 && (
          <div
            dangerouslySetInnerHTML={{
              __html: myContext["data"]["helpHTML"],
            }}></div>
        )}

        <iframe
          width='100%'
          border-width='0'
          frameBorder='0'
          height='5000px'
          src='https://docs.google.com/document/d/e/2PACX-1vSxGnVWtC-1VMkwmmY9r-BVwfYymhByD2C3CaSiwWVlWTXurzXNiDvBLpKie2KszFPVu3Jmcp0a2QzK/pub?embedded=true'></iframe>
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </div>
    </div>
  );
}
/*
      {props.user && props.user.length > 0 && <div dangerouslySetInnerHTML={{ __html: {helpHTML} }}></div>}
      Page {pageNumber} of {numPages}
      {"<Document file={doc} onLoadSuccess={onDocumentLoadSuccess}>
         <Page pageNumber={pageNumber width="1000px"} />
         <Page pageNumber={pageNumber + 1 width="1000px"} />
         <Page pageNumber={pageNumber + 2 width="1000px"} />
      </Document> "}
*/
